let ArrayUnityConfig = {
  loaderUrl: '',
  dataUrl: '',
  frameworkUrl: '',
  codeUrl: '',
};

switch (import.meta.env.VITE_UNITYWEBGL_BUCKET) {
  case 'local':
    ArrayUnityConfig.loaderUrl = 'unity-container/array.loader.js';
    ArrayUnityConfig.dataUrl = 'unity-container/array.data';
    ArrayUnityConfig.frameworkUrl = 'unity-container/array.framework.js';
    ArrayUnityConfig.codeUrl = 'unity-container/array.wasm';
    break;
  case 'staging':
  case 'qa':
    ArrayUnityConfig.loaderUrl =
      'https://labs.dragonbox.academy/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.loader.js';
    ArrayUnityConfig.dataUrl =
      'https://labs.dragonbox.academy/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.data.br';
    ArrayUnityConfig.frameworkUrl =
      'https://labs.dragonbox.academy/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.framework.js.br';
    ArrayUnityConfig.codeUrl =
      'https://labs.dragonbox.academy/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.wasm.br';
    break;
  case 'production':
    ArrayUnityConfig.loaderUrl =
      'https://labs.dragonbox.com/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.loader.js';
    ArrayUnityConfig.dataUrl =
      'https://labs.dragonbox.com/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.data.br';
    ArrayUnityConfig.frameworkUrl =
      'https://labs.dragonbox.com/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.framework.js.br';
    ArrayUnityConfig.codeUrl =
      'https://labs.dragonbox.com/assets/ArrayPuzzleLaboratory/Build/ArrayPuzzleLaboratory.wasm.br';
    break;
  default:
    ArrayUnityConfig.loaderUrl =
      'https://labs.dragonbox.dev/assets/ArrayPuzzleDevLaboratory/Build/ArrayPuzzleDevLaboratory.loader.js';
    ArrayUnityConfig.dataUrl =
      'https://labs.dragonbox.dev/assets/ArrayPuzzleDevLaboratory/Build/ArrayPuzzleDevLaboratory.data.br';
    ArrayUnityConfig.frameworkUrl =
      'https://labs.dragonbox.dev/assets/ArrayPuzzleDevLaboratory/Build/ArrayPuzzleDevLaboratory.framework.js.br';
    ArrayUnityConfig.codeUrl =
      'https://labs.dragonbox.dev/assets/ArrayPuzzleDevLaboratory/Build/ArrayPuzzleDevLaboratory.wasm.br';
    break;
}

export default ArrayUnityConfig;
