import { useEffect, useCallback, useRef } from 'react';
import './activity-hub.scss';
import { LabCard } from './components/lab-card';
import { Video } from './components/video';
import { LabCategory } from './components/lab-category';
import arrow_icon from '../../../../assets/images/icons/arrow.svg';
import { ActivityInfo, getPuzzleGamesData, PuzzleGame } from '../config/puzzle-hub-config';
import { useParams } from 'react-router-dom';
import { trackEvent } from '@puzzles/core/amplitude';
import { getActivityPageViewEventName, getActivityVideoClickedEventName } from '@puzzles/core/amplitude/utils';

export const ActivityHub = (props: ActivityInfo) => {
  window.location.href = 'https://create.kahoot.it/math-games/fractions';

  const pageLoadedRef = useRef(false);
  const { activity } = useParams();

  const puzzles = getPuzzleGamesData(activity)
    .filter((puzzle) => puzzle.visibility === 'public' || puzzle.visibility === undefined)
    .reduce((group, item) => {
      const { category } = item;
      group[category] = group[category] ?? [];
      group[category].push(item);
      return group;
    }, {} as Record<string, PuzzleGame[]>);

  const puzzlesByCategory = Array.from(new Map(Object.entries(puzzles)).entries())
    .map(([category, labs]) => ({
      category: category,
      labs: labs,
    }))
    .filter((item) => item.category != 'Test');

  const onLoad = useCallback((event: any) => {
    console.log(event);
  }, []);

  const handleLoad = () => {
    if (pageLoadedRef.current) return;
    pageLoadedRef.current = true;

    trackEvent({ eventName: getActivityPageViewEventName(activity) });
  };

  useEffect(() => {
    window.onload = () => {
      // Track event when load the page using the url
      handleLoad();
    };

    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, [onLoad]);

  useEffect(() => {
    // Track event when load the page using the router
    handleLoad();
  }, []);

  return (
    <div className="puzzle-hub">
      <div className="puzzle-hub-header" style={{ backgroundImage: `url(${props.background})` }}>
        <h1 className="puzzle-hub-header-title" dangerouslySetInnerHTML={{ __html: props.title }}></h1>
        <div className="puzzle-hub-header-content" style={{ background: `${props.headerThemeColor}` }}>
          <div className="puzzle-hub-header-content-text">
            <h2
              className="puzzle-hub-subtitle"
              style={props.textColor ? { color: `${props.textColor}` } : { color: `#fff` }}
            >
              {props.subTitle} <br />
            </h2>
            <ul
              className="puzzle-hub-text"
              style={props.textColor ? { color: `${props.textColor}` } : { color: `#fff` }}
            >
              <li>
                <strong>1. Choose a game</strong>
                {props.description.choose}
              </li>
              <li>
                <strong>2. Host and join</strong>
                {props.description.host}
              </li>
              <li>
                <strong>3. Start the game</strong>
                {props.description.start}
              </li>
            </ul>
          </div>

          <div className="puzzle-hub-header-content-visual">
            <Video
              videoId={props.videoId}
              onPlay={() => trackEvent({ eventName: getActivityVideoClickedEventName(activity) })}
            />
          </div>
        </div>
      </div>

      <div className="puzzle-hub-labs" style={{ backgroundColor: `${props.labsThemeColor}` }}>
        <div className="puzzle-hub-labs-container">
          {puzzlesByCategory.map((category, index) => {
            return index === 0 ? (
              <div className="puzzle-hub-first" key={index} style={{ background: `${props.firstLabThemeColor}` }}>
                <div className="puzzle-hub-first-lab">
                  <h3 className="puzzle-hub-first-title">
                    <strong>First time here?</strong>
                    <br /> Start with this game.
                  </h3>
                  <img src={arrow_icon} alt="arrow icon" className="puzzle-hub-first-icon" loading="lazy" />
                </div>
                <LabCard lab={category.labs[0]} key={index} />
              </div>
            ) : (
              <LabCategory category={category} key={index} />
            );
          })}
        </div>
      </div>
    </div>
  );
};
