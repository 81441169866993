export type PuzzleMainHubDetails = {
  activityVersion?: string;
  activity: string;
  category: string;
  title: string;
  level: string;
  backgroundColor: string;
  categoryColor: string;
  video?: string;
  videoTrackMsg?: string;
  picture?: string;
  visual: string;
  description: string;
  url: string;
  cta: string;
  ctaTrackMsg?: string;
  carousel: {
    carouselItem: string;
  }[];
};

export const puzzlesMainHub: PuzzleMainHubDetails[] = [
  {
    activityVersion: 'latest',
    activity: 'fractions',
    category: 'Puzzle Game',
    title: '<span class="nowrap">Duck-Duck</span> Fractions',
    level: 'Grade 2 to 4',
    backgroundColor: 'linear-gradient(290.74deg, #2E2B98 71.46%, #6764FF 94.72%)',
    categoryColor: 'rgba(138, 206, 255, 1)',
    video: '830193224?h=4d09d029fe',
    videoTrackMsg: 'Hub page Video fractions Clicked',
    visual: '/multiplayer/features/puzzle-main-hub/fractions/fractions-card-picture.png',
    description:
      'Learn fractions with <span class="nowrap">Duck-Duck</span>, the first duck space traveler! Explore fractions through a variety of examples and activities in this space-tactic puzzle adventure!',
    url: '/start/fractions',
    cta: 'Play',
    ctaTrackMsg: 'Fractions Play CTA Clicked',
    carousel: [
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/fractions/carousel/visual-1.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/fractions/carousel/visual-2.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/fractions/carousel/visual-3.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/fractions/carousel/visual-4.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/fractions/carousel/visual-1.png',
      },
    ],
  },
  {
    activity: 'multiplication',
    category: 'Puzzle Game',
    title: 'Multiplication Crew',
    level: 'Grade 2 to 4',
    backgroundColor: 'linear-gradient(286.85deg, #FFA800 77.3%, #FFC700 85.72%)',
    categoryColor: 'rgba(255, 167, 1,1)',
    video: '799778078?h=c0549b926f',
    videoTrackMsg: 'Hub page Video multiplication Clicked',
    visual: '/multiplayer/features/puzzle-main-hub/multiplication/multi-card.png',
    description:
      'Explore multiplication through adaptive puzzle games. Players will solve problems on the game board, advance to new levels, and gather coins to make their chest grow!',
    url: '/start/multiplication',
    cta: 'Play',
    ctaTrackMsg: 'Multi Play CTA Clicked',
    carousel: [
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/multiplication/carousel/visual1.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/multiplication/carousel/visual2.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/multiplication/carousel/visual3.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/multiplication/carousel/visual4.png',
      },
      {
        carouselItem: '/multiplayer/features/puzzle-main-hub/multiplication/carousel/visual1.png',
      },
    ],
  },
];
