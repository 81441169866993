export interface DeviceSupport {
  isSupported: boolean;
  errorMessage: string;
}

export const checkDeviceSupport = (): DeviceSupport => {
  if ((window === null || window === void 0 ? void 0 : window.WebGLRenderingContext) == undefined) {
    return { isSupported: false, errorMessage: 'WebGLRenderingContext not available' };
  }

  const canvas: HTMLCanvasElement = document.createElement('canvas');
  let gl: any = canvas.getContext('webgl2');
  if (gl === null) {
    gl = canvas.getContext('webgl');

    if (gl === null) {
      gl = canvas.getContext('experimental-webgl');

      if (gl === null) {
        return { isSupported: false, errorMessage: 'canvas.getContext not available' };
      }
    }
  }

  //webgl is available. Now check highp shader feature
  const precisionFormat: any = gl?.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT);

  if (precisionFormat === null || precisionFormat.precision == 0) {
    return {
      isSupported: false,
      errorMessage: "WebGL FRAGMENT_SHADER doesn't support highp",
    };
  }

  return { isSupported: true, errorMessage: '' };
};
