//based on https://github.com/mrsungura/payload-next/blob/949cc94028557a59185b4606940740bede4c7dfc/src/components/VideoPlayer/index.tsx

import { useEffect, useRef } from 'react';
import Player from '@vimeo/player';

type VideoProps = {
  videoId: string;
  onPlay: () => void;
};

export const Video = (props: VideoProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const { current: iframe } = iframeRef;
    let newPlayer: Player;
    if (iframe) {
      newPlayer = new Player(iframe);
      newPlayer.on('play', props.onPlay);
    }

    //cleanup event
    return () => {
      if (iframe && newPlayer) {
        newPlayer.off('play', props.onPlay);
      }
    };
  }, [props.onPlay]);

  return (
    <div className="video">
      <iframe
        title="Vimeo player"
        ref={iframeRef}
        src={`https://player.vimeo.com/video/${props.videoId}`}
        allowFullScreen={true}
        allow="autoplay; fullscreen; picture-in-picture"
      />
    </div>
  );
};
