import { addToInventory } from '../../features/game-mode/chest/components/inventory/inventory';
import { store } from '../../../store';
import { setSendGemPanelActive } from '../../features/game-mode/chest/components/chest-game-mode-component/redux';
import { setHudDimActive } from '../../features/activity/array-unity/components/activity/redux';

export const DebugButtons = () => {
  const addGemsToInventory = () => {
    store.dispatch(setHudDimActive(true));
    setTimeout(() => {
      store.dispatch(setHudDimActive(false));
    }, 2500);
    addToInventory(1, 8);
  };

  const openSendGemsPanel = () => {
    store.dispatch(setSendGemPanelActive(true));
  };

  if (import.meta.env.VITE_DEBUG_MODE_ENABLED === 'true') {
    return (
      <div>
        <button className="sendGemsPanelButton" onClick={openSendGemsPanel}>
          Open SendGems Panel
        </button>
        <button className="addGemsToInventory" onClick={addGemsToInventory}>
          Fill up inventory
        </button>
      </div>
    );
  }
  return null;
};
