import { Video } from '../../puzzle-activity-hub/activity-hub/components/video';
import { Link } from 'react-router-dom';
import { PuzzleMainHubDetails } from '../config/puzzle-main-hub-config';
import { trackEvent } from '@puzzles/core/amplitude';
import { ActivityClickOnLinkEvent, HostActivityVideoPlayEvent } from '@puzzles/core/amplitude/types';

export const HubGameDetails = ({ game }: { game: PuzzleMainHubDetails }) => {
  return (
    <div
      className="hub-game"
      style={{
        background: `${game.backgroundColor}`,
      }}
    >
      {game.activityVersion === 'latest' && <div className="hub-game-new"></div>}
      {game.category && (
        <h2
          className="hub-game-header-category"
          style={{
            color: `${game.categoryColor}`,
          }}
        >
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.58465 0.939551C4.58465 0.627758 4.83741 0.375 5.1492 0.375H6.46751C6.7793 0.375 7.03206 0.627758 7.03206 0.93955V1.52367C7.03206 1.83547 7.28482 2.08822 7.59661 2.08822H9.94991C10.2617 2.08822 10.5145 2.34098 10.5145 2.65278V4.16144C10.5145 4.47323 10.2617 4.72599 9.94991 4.72599H9.26415C8.95236 4.72599 8.6996 4.97874 8.6996 5.29054V6.67163C8.6996 6.98342 8.95236 7.23618 9.26415 7.23618H9.94991C10.2617 7.23618 10.5145 7.48893 10.5145 7.80073V9.6035C10.5145 9.91529 10.2617 10.168 9.94991 10.168H7.07366C6.76186 10.168 6.50911 9.91529 6.50911 9.6035V8.76822C6.50911 8.45643 6.25635 8.20367 5.94455 8.20367H5.08643C4.77463 8.20367 4.52187 8.45643 4.52187 8.76822V9.6035C4.52187 9.91529 4.26912 10.168 3.95732 10.168H2.99919C2.6874 10.168 2.43464 9.91529 2.43464 9.6035V7.77984C2.43464 7.46805 2.18188 7.21529 1.87009 7.21529H1.03525C0.723461 7.21529 0.470703 6.96253 0.470703 6.65074V5.20687C0.470703 4.89507 0.723462 4.64231 1.03525 4.64231H1.87009C2.18188 4.64231 2.43464 4.38956 2.43464 4.07776V2.65278C2.43464 2.34098 2.6874 2.08822 2.99919 2.08822H4.0201C4.33189 2.08822 4.58465 1.83547 4.58465 1.52367V0.939551Z"
              fill={game.categoryColor}
            />
          </svg>
          {game.category}
        </h2>
      )}
      <div className="hub-game-header">
        {game.title && <h3 className="hub-game-header-title" dangerouslySetInnerHTML={{ __html: game.title }}></h3>}
        {game.level && <h4 className="hub-game-header-level">{game.level}</h4>}
      </div>

      <div className="hub-game-content">
        <div className="hub-game-content-left">
          {game.video ? (
            <Video
              videoId={game.video}
              onPlay={() =>
                game.videoTrackMsg &&
                trackEvent<HostActivityVideoPlayEvent>({ eventName: game.videoTrackMsg, video_id: game.video })
              }
            />
          ) : (
            <img src={game.picture} loading="lazy" alt={game.title} className="hub-game-content-left-picture" />
          )}
          {game.carousel.length > 0 && (
            <div className="hub-game-content-left-carousel">
              {game.carousel.map((img, indexImg) => (
                <img key={indexImg} src={img.carouselItem} loading="lazy" alt={game.title} />
              ))}
            </div>
          )}
        </div>
        <div className="hub-game-content-right">
          {game.visual && (
            <img src={game.visual} alt={game.title} loading="lazy" className="hub-game-content-right-visual" />
          )}
          {game.description && (
            <p
              className="hub-game-content-right-description"
              dangerouslySetInnerHTML={{ __html: game.description }}
            ></p>
          )}
          {game.url && (
            <Link
              to={game.url}
              title={game.title}
              target="_blank"
              className="hub-game-content-right-btn"
              onClick={() => {
                game.ctaTrackMsg &&
                  trackEvent<ActivityClickOnLinkEvent>({ eventName: game.ctaTrackMsg, title: game.title });
              }}
            >
              {game.cta}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
