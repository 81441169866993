import { Link, useParams } from 'react-router-dom';
import { PuzzleGame } from '../../config/puzzle-hub-config';
import { trackEvent } from '@puzzles/core/amplitude';
import { getActivityClickOnThumbnailEventName } from '@puzzles/core/amplitude/utils';

export const LabCard = ({ lab }: { lab: PuzzleGame }) => {
  const { activity } = useParams();

  return (
    <Link
      to={lab.url}
      className="puzzle-hub-lab"
      title={lab.title}
      onClick={() =>
        trackEvent({
          eventName: getActivityClickOnThumbnailEventName(activity),
          title: lab.title,
        })
      }
    >
      {lab.image && (
        <div className="puzzle-hub-lab-visual">
          <img src={lab.image} alt={lab.title} loading="lazy" />
        </div>
      )}
      <div className="puzzle-hub-lab-content">
        <img src={lab.companyLogo} alt={lab.companyName} className="puzzle-hub-lab-content-logo" loading="lazy" />
        <div className="puzzle-hub-lab-content-text">
          <h3 className="puzzle-hub-lab-content-title">{lab.title}</h3>
          <div className="puzzle-hub-lab-content-badge">
            {lab.icon && <img src={lab.icon} alt="check icon" loading="lazy" />}
            <span>{lab.companyName}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};
