import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './pause-panel.scss';
import { store } from '../../../store';
import { selectControllerEndCountdown, selectControllerGamePaused } from '../../features/puzzle-controller/redux';
import { PausePixiStage } from './pause-pixi-stage';
import gsap from 'gsap';
import { setBadgeActive } from '../badge/redux';

export const PausePanel = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const pixiStage = useRef<PausePixiStage | null>(null);
  const gamePaused = useSelector(selectControllerGamePaused);
  const gameEndCountdown = useSelector(selectControllerEndCountdown);
  const [panelClasses, setPanelClasses] = useState<string>('pause-panel');

  useEffect(() => {
    const textAnimation = gsap.to(textRef.current, {
      y: '-=20',
      duration: 0.3,
      yoyo: true,
      repeat: -1,
      paused: true,
    });
    if (gamePaused) {
      textAnimation.play();
      let classes = 'pause-panel pause-panel-show';
      if (gameEndCountdown) classes += ' pause-panel-end';
      setPanelClasses(classes);
      if (ref.current && !pixiStage.current) {
        store.dispatch(setBadgeActive(false));
        pixiStage.current = new PausePixiStage(ref.current);
      }
    } else {
      textAnimation.pause();
      setPanelClasses('pause-panel');
      store.dispatch(setBadgeActive(true));
      setTimeout(() => {
        pixiStage.current?.destroy();
        pixiStage.current = null;
      }, 300);
    }
    return () => {
      textAnimation.kill();
    };
  }, [gamePaused, ref]);

  return (
    <section className={panelClasses} ref={ref}>
      <p ref={textRef}>
        <span role="img" aria-label="Look up">
          👆
        </span>{' '}
        LOOK UP
      </p>
    </section>
  );
};
