import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found-header"></div>
      <h1 className="not-found-title">Page does not exist</h1>
      <h2 className="not-found-subtitle">You can try one of our games here</h2>
      <Link to="/start" className="not-found-cta">
        Play Puzzles
      </Link>
      <div className="hub-footer-logo"></div>
    </div>
  );
};
