import '@puzzles/core/activity/activity-component.scss';

import { DuckFractionActivity } from '../../../../../../fractions/duck-fraction-activity';
import { DebugButtons } from '../../../../../components/puzzle-test-debug-buttons/puzzle-test-debug-buttons';
import { ActivityComponent, ActivityController } from '@puzzles/core/activity/activity-component';
import { useRef } from 'react';
import { executePlaySessionWithActivity } from 'src/fractions/puzzle-session';
import { sessionBundle } from 'src/fractions/puzzle-data-bundle';

export const FractionsActivityComponent = () => {
  const activityRef = useRef<DuckFractionActivity | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const urlParams = new URLSearchParams(window.location.search);

  const controller: ActivityController<DuckFractionActivity> = {
    bind: (activity: DuckFractionActivity) => {
      activityRef.current = activity;
      if (urlParams.has('list')) {
        const session = sessionBundle.get(urlParams.get('list')!)!;
        void executePlaySessionWithActivity(
          activity,
          session
          //asyncGenerator(generatePlaylist(urlParams.get('list')!.split(' ')))
        );
      }
    },
    unbind: () => {
      activityRef.current = null;
    },
  };

  return (
    <div className="fractions-activity-component">
      <ActivityComponent constructor={DuckFractionActivity} controller={controller} container={containerRef} />
      <DebugButtons />
    </div>
  );
};
