import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectControllerNickname, selectControllerAvatar } from '../../features/puzzle-controller/redux';
import './avatar-preview.scss';
import { gsap } from 'gsap';

export const AvatarPreview = () => {
  const controllerNickname = useSelector(selectControllerNickname);
  const controllerAvatar = useSelector(selectControllerAvatar);

  const avatarRef = useRef(null);
  const avatar = controllerAvatar ? <img src={controllerAvatar} alt="avatar" ref={avatarRef} /> : <p></p>;

  useEffect(() => {
    if (controllerAvatar) {
      gsap.set(avatarRef.current, {
        opacity: 0,
        marginTop: 20,
      });
      gsap.to(avatarRef.current, {
        opacity: 1,
        marginTop: 0,
        duration: 0.3,
        ease: 'Back.easeOut',
      });
    }
  }, [controllerAvatar]);

  return (
    <div className="absolute-wrapper">
      <div className="main-content">
        <div className="kahoot-theme">
          <div className="content">
            <div className="bg">
              <div className="bg-square"></div>
              <div className="bg-circle"></div>
              <div className="bg-pin">
                <div className="game-header">
                  <div className="avatar-preview">
                    <div className="avatar-container">{avatar}</div>
                    <h2>{controllerNickname}</h2>
                    {/* TODO: A countdown timer like in kahoot? */}
                    <p>You&apos;re in! Get ready to start...</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
