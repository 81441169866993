/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from './app';
import HttpsRedirect from 'react-https-redirect';

import './index.css';
import { isDevelopment } from './common/utils/is-development';
import { AmplitudeApiKey, initializeAmplitude } from './core/amplitude';

initializeAmplitude(AmplitudeApiKey);

if (!isDevelopment()) {
  Sentry.init({
    dsn: 'https://178db13ddf144645a42142111fc42afa@o116966.ingest.sentry.io/4504537211666432',
    integrations: [new BrowserTracing()],
    environment: import.meta.env.VITE_ENVIRONMENT,
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root')!;
const persistor = persistStore(store);
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/*/!*  TODO: use of httpsredirect is temporary and not production safe, while we are playtesting on Heroku. */}
      {/* For production we must configure this server side*!/*/}
      <HttpsRedirect>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </HttpsRedirect>
    </Provider>
  </React.StrictMode>
);
