// eslint-disable-next-line no-restricted-syntax
import { Application, Assets, Container, Point, Sprite, Text, TextStyle, Texture } from 'pixi.js';
import { GemTypesStatic } from '../inventory/gem-types';
import gsap from 'gsap';
import swirl from './images/swirl.jpg';
import { ParticleView } from '../../../../../../player/features/game-mode/chest/particle-view';
import triangleButton from '../../../../../../assets/images/UI/triangle.svg';
import { wait } from '@puzzles/core/async/awaitable';
import { Disposable } from '@puzzles/core/interfaces/disposable';

export class SendGemsActivity implements Disposable {
  app: Application;
  screenSize = [window.innerWidth, window.innerHeight];
  ratio = this.screenSize[0] / this.screenSize[1];
  gemPositions = [0];
  //swirlFilter: Filter;
  htmlContainer: HTMLElement;
  gemContainer: Container;
  feedbackContainer: Container;
  gems: Sprite[];
  empty: boolean;
  private sendGemsEmitterJson: any;
  private sendAllGemsEmitterJson: any;
  private gemAppearEmitter?: any;

  private remindContainer: Container = new Container();
  private isRemindShown = false;

  private lookupText: Text;
  private lookUptimeline = gsap.timeline();

  private animating = false;

  get getAnimating() {
    return this.animating;
  }

  constructor(container: HTMLElement) {
    this.empty = false;

    this.app = new Application({
      width: this.screenSize[0],
      height: this.screenSize[1],
      backgroundAlpha: 0,
      resizeTo: window,
      antialias: false,
      premultipliedAlpha: false,
    });

    this.app.renderer.on('resize', () => {
      this.resize();
    });

    this.htmlContainer = container;
    container.appendChild(this.app.view as any);
    const stage = this.app.stage;

    this.gemContainer = new Container();
    this.gemContainer.zIndex = 100;
    stage.addChild(this.gemContainer);

    this.feedbackContainer = new Container();
    this.feedbackContainer.zIndex = 90;
    this.feedbackContainer.width = window.innerWidth;
    this.feedbackContainer.height = window.innerHeight;
    stage.addChild(this.feedbackContainer);

    // this.swirlFilter = new TwistFilter({
    //   angle: 10,
    //   radius: 500,
    //   offset: new Point(window.innerWidth / 2, window.innerHeight / 2),
    // });

    const bg = Sprite.from(swirl);
    bg.width = window.innerWidth;
    bg.height = window.innerHeight;
    bg.filterArea = this.app.renderer.screen;
    //bg.filters = [this.swirlFilter];
    this.app.stage.addChild(bg);

    const style = new TextStyle({
      dropShadow: true,
      dropShadowAngle: 90,
      dropShadowBlur: 4,
      dropShadowDistance: 3,
      fill: '#ffb800',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 40,
      letterSpacing: 2,
    });

    const triangle = new Sprite(Texture.from(triangleButton));
    triangle.y = -30;
    triangle.scale.set(0.5);
    triangle.anchor.set(0.5, 0);
    this.remindContainer.addChild(triangle);

    const screen = this.app.screen;
    const remind = 'TAP or HOLD';
    const remindText = new Text(remind, style);
    remindText.anchor.set(0.5, 0);
    this.remindContainer.addChild(remindText);
    this.remindContainer.position.set(screen.width / 2, screen.height / 1.2);
    stage.addChild(this.remindContainer);
    this.remindContainer.alpha = 0;

    // We should consider putting text like this (that isn't essential gameplay text) in the html layer.
    // It's expensive to render text in pixi, and not very flexible.
    const style2 = new TextStyle({
      dropShadow: true,
      dropShadowAngle: 90,
      dropShadowBlur: 4,
      dropShadowDistance: 3,
      fill: '#ffb800',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: 35,
      letterSpacing: 2,
    });
    const lookUp = '👆 LOOK UP';
    this.lookupText = new Text(lookUp, style2);
    this.lookupText.position.set(screen.width / 2, screen.height / 2);
    this.lookupText.anchor.set(0.5);
    this.lookupText.scale.set(0);
    stage.addChild(this.lookupText);

    // this.app.ticker.add(delta => {
    //   this.swirlFilter.uniforms.radius += 0.5 * delta;
    // });

    this.gems = [];

    this.sendGemsEmitterJson = null;
    this.sendAllGemsEmitterJson = null;

    void this.preloadGemFeedbacks();
    stage.sortChildren();
    this.resize();
  }

  public remindAnimation() {
    if (this.isRemindShown) return;

    this.isRemindShown = true;
    gsap.killTweensOf(this.remindContainer);
    const pos = this.app.screen.height / 1.15;
    gsap.fromTo(this.remindContainer, { y: pos }, { y: pos - 5, duration: 0.6 });
    gsap.fromTo(this.remindContainer, { alpha: 0.3 }, { alpha: 1, duration: 0.6 });
  }

  public hideRemindAnimation() {
    if (!this.isRemindShown) return;

    this.isRemindShown = false;
    gsap.killTweensOf(this.remindContainer);
    gsap.to(this.remindContainer, { alpha: 0, duration: 0.3 });
    gsap.to(this.remindContainer, { y: this.app.screen.height / 1.15, duration: 0.3 });
  }

  public showLookUp() {
    if (this.empty) return;
    this.lookupText.y = this.app.screen.height / 1.5;
    this.lookUptimeline.kill();
    this.lookUptimeline = gsap.timeline();
    this.lookUptimeline
      .to(this.lookupText.scale, { x: 1.8, y: 1.8, duration: 0.15 })
      .to(this.lookupText.scale, { x: 1, y: 1, duration: 0.1 })
      .to(this.lookupText.scale, { x: 0, y: 0, duration: 0.2, delay: 1.5 });
  }

  public showLookUpCenter() {
    this.lookupText.y = this.app.screen.height / 2.1;
    this.lookUptimeline.kill();
    this.lookUptimeline = gsap.timeline();
    this.lookUptimeline
      .to(this.lookupText.scale, { x: 1.8, y: 1.8, duration: 0.1, delay: 0.2 })
      .to(this.lookupText.scale, { x: 1.1, y: 1.1, duration: 0.1 });
  }

  async preloadGemFeedbacks() {
    this.sendGemsEmitterJson = await Assets.load('/chest-data/send-gems/send_gems_emitter.json');
    this.gemAppearEmitter = await Assets.load('/chest-data/send-gems/emitter_gem_appear.json');
    this.sendAllGemsEmitterJson = await Assets.load('/chest-data/send-gems/send_all_gems_emitter.json');
  }

  gemFeedback(pos: Point) {
    if (this.sendGemsEmitterJson == null) return;
    const particles = new ParticleView(this.sendGemsEmitterJson, this.app, this.feedbackContainer, true);
    particles.zIndex = 5;
    particles.emitter.updateOwnerPos(pos.x, pos.y);
  }

  sendAllGemsFeedback(x: number, y: number) {
    if (this.sendAllGemsEmitterJson == null) return;
    const particles = new ParticleView(this.sendAllGemsEmitterJson, this.app, this.feedbackContainer, true);
    particles.zIndex = 5;
    particles.emitter.updateOwnerPos(x, y);
  }

  shakeAGem = (gem: any, speed = 0.07, yVal = '+=9') => {
    gsap.to(gem, {
      y: yVal,
      // delay: delay,
      duration: speed,
      ease: 'Linear.easeNone',
      onComplete: () => {
        if (yVal == '+=9') {
          yVal = '-=9';
        } else {
          yVal = '+=9';
        }
        speed = Math.max(speed * 0.7, 0.03);
        this.shakeAGem(gem, speed, yVal);
      },
    });
  };

  shakeAllGems() {
    for (let i = 0; i < this.gems.length; i++) {
      this.shakeAGem(this.gems[i]);
    }
  }

  destroyShakeAllGems() {
    if (this.empty) return;
    for (let i = 0; i < this.gems.length; i++) {
      gsap.killTweensOf(this.gems[i]);
    }
  }

  sendAllGems() {
    this.destroyShakeAllGems();
    this.empty = true;
    let delay = 0;
    this.gems.sort(() => 0.5 - Math.random());
    this.sendAllGemsFeedback(window.innerWidth / 2, window.innerHeight);
    for (let i = 0; i < this.gems.length; i++) {
      const gem = this.gems[i];
      gsap.to(gem, {
        y: -(window.innerHeight / 2 + 200),
        delay: delay,
        duration: 0.2,
        ease: 'Expo.EaseOut',
        onComplete: () => {
          if (gem) gem.destroy();
        },
      });
      delay = delay + 0.03;
    }
  }

  sendAGem = () => {
    const gem = this.gems.shift();
    if (!gem || this.empty) return;
    const tl = gsap.timeline();
    tl.to(gem.position, {
      y: gem.y + 100,
      duration: 0.15,
      onComplete: () => {
        if (gem) this.gemFeedback(gem.getGlobalPosition());
      },
    });
    tl.to(gem.position, {
      y: -(window.innerHeight / 2 + 200),
      duration: 0.3,
      onComplete: () => {
        if (gem) gem.destroy();
      },
    });
  };

  async spawnGem(gemType: number, gem: Sprite) {
    gem.texture = await Assets.load(GemTypesStatic[gemType]);
    gem.anchor.set(0.5);
    gem.scale.set(0);
    void this.updateGemContainerSize();

    const delayVal = 200 + Math.random() * 300;
    await wait(delayVal);

    const particles = new ParticleView(this.gemAppearEmitter, this.app, this.feedbackContainer, true);
    particles.zIndex = 5;
    const globalPos = gem.getGlobalPosition();
    particles.emitter.updateOwnerPos(globalPos.x, globalPos.y);

    gsap.to(gem.scale, {
      x: 1,
      y: 1,
      duration: 0.2,
      ease: 'Back.easeOut',
      onComplete: () => {
        this.animating = false;
        particles.destroy();
        gsap.to(gem.position, {
          y: '+=10',
          repeat: -1,
          yoyo: true,
          duration: 0.7 + Math.random() * 0.1,
          ease: 'Power1.easeInOut',
        });
      },
    });
  }

  async addGem(gemType: number) {
    this.animating = true;
    const gem = new Sprite();
    this.gems.push(gem);
    gem.position.y = Math.random() * 50;
    gem.position.x = this.gemPositions[this.gemPositions.length - 1] - Math.random() * 10;
    gem.anchor.set(0.5, 0.5);
    gem.zIndex = 10;
    this.gemPositions.push(gem.position.x + gem.width + 100);
    this.gemContainer.addChild(gem);
    await this.spawnGem(gemType, gem);
    this.lookupText.scale.set(0);
    this.remindAnimation();
  }

  updateGemContainerSize() {
    if (this.app.renderer) {
      const aspectRatio = this.gemContainer.width / this.gemContainer.height;
      this.gemContainer.width = window.innerWidth * 0.8;
      if (this.gemContainer.width > this.gems.length * 70) this.gemContainer.width = this.gems.length * 70;
      this.gemContainer.height = this.gemContainer.width / aspectRatio;
      this.gemContainer.position.x = this.app.renderer.width / 2 - this.gemContainer.width / 2;
      this.gemContainer.position.y =
        this.app.renderer.height / 2 - this.app.renderer.height * 0.1 - this.gemContainer.height / 2;
    }
  }

  dispose() {
    this.gems.forEach((gem) => {
      gsap.killTweensOf(gem);
    });
    this.app.destroy(true);
  }

  resize() {
    this.updateGemContainerSize();
  }
}
