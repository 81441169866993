import { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Unity, useUnityContext } from 'react-unity-webgl';
import ArrayUnityConfig from './config/array-unity-config';

import companyLogo from '../../../../../../assets/images/dragonbox.png';
import gameLogo from '../../../../../../assets/images/array_logo.png';
import { BlockingError } from '../../../../../../common/components/blocking-error/blocking-error';

import './array-activity-component.scss';

import { selectControllerCID } from '../../../../puzzle-controller/redux';
import { store } from '../../../../../../store';
import { GameServiceContext } from '../../../../../../common/state/game-service';
import {
  ArrayPuzzleQuizCreationData,
  ArrayPuzzleQuizQuestionData,
  setQuizCreationData,
  setQuizPanelActive,
} from '../quiz/redux';
import {
  incrementSkips,
  selectControllerLevelName,
  selectControllerLevelsCompleted,
  setHudDimActive,
  setLevelIndexInPlaylist,
  setLevelName,
  setLevelsCompleted,
} from './redux';
import { selectSendGemPanelActive } from '../../../../game-mode/chest/components/chest-game-mode-component/redux';
import { DebugButtons } from '../../../../../components/puzzle-test-debug-buttons/puzzle-test-debug-buttons';
import { puzzleActivityEvents } from '../../../events/puzzle-activity-events';
import { EndPuzzleLevelEventData } from '@puzzles/common/typedefs/puzzles/types';
import { ActivityId } from '@puzzles/core/types';
import { BusEventType } from '@puzzles/core/event-bus/constants';

declare global {
  interface Window {
    showBlockingError: any;
    levelComplete: any;
    loadUnityGameStatus: any;
    openQuiz: any;
    updateAvatar: any;
    updateCurrentLevel: any;
    skipToNextSection: any;
  }
}

export const ArrayActivityComponent = () => {
  const gemPanelActive = useSelector(selectSendGemPanelActive);
  const levelName = useSelector(selectControllerLevelName);
  const levelsCompleted = useSelector(selectControllerLevelsCompleted);
  const { controllerClient } = useContext(GameServiceContext);
  const controllerCID = useSelector(selectControllerCID);

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
  } = useUnityContext({
    loaderUrl: ArrayUnityConfig.loaderUrl,
    dataUrl: ArrayUnityConfig.dataUrl,
    frameworkUrl: ArrayUnityConfig.frameworkUrl,
    codeUrl: ArrayUnityConfig.codeUrl,
  });

  const [blockingError, setBlockingError] = useState<string>();

  useEffect(() => {
    if (gemPanelActive) sendMessage('ResultView', 'OnAllGemsSent');
  }, [gemPanelActive]);

  const dimScreen = () => {
    store.dispatch(setHudDimActive(true));
    setTimeout(() => {
      store.dispatch(setHudDimActive(false));
    }, 2500);
  };

  const incrementArraysCreated = (arraysCreated: number) => {
    //controller receives how many arrays were created from Unity, onLevelComplete
    //and sends this value to the Player, to increment the count
    controllerClient.sendToPlayer(
      JSON.stringify({
        type: 'UpdateArrayStats',
        payload: {
          cid: controllerCID,
          arraysCreated: arraysCreated,
        },
      })
    );
  };

  window.skipToNextSection = () => {
    //update stats
    store.dispatch(incrementSkips());
  };

  window.updateCurrentLevel = (levelName: string, levelIndex: number) => {
    //const state = store.getState();
    store.dispatch(setLevelIndexInPlaylist(levelIndex));
    store.dispatch(setLevelName(levelName));
    /*
    const urlParams: any = new URLSearchParams(window.location.search);
    urlParams.set('url', levelName);
    var newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      urlParams;
    window.history.pushState({ path: newurl }, '', newurl);
    */
  };

  //function that Unity calls at start to load game status (saved in localStorage)
  window.loadUnityGameStatus = () => {
    const json: string = JSON.stringify({
      currentLevelName: levelName,
      levelsCompleted: levelsCompleted,
      multiplayerSessionId: store.getState().common.multiplayerSessionId,
    });
    return json;
  };

  window.levelComplete = (levelNumber: number, arraysCreated: number) => {
    dimScreen();
    store.dispatch(setLevelsCompleted(levelNumber));
    incrementArraysCreated(arraysCreated);

    // TODO
    puzzleActivityEvents.publish<EndPuzzleLevelEventData>(BusEventType.EndLevel, {
      activity: ActivityId.ArrayCrew,
      levelId: 'unknown',
      levelIndex: levelNumber,
      playSessionId: store.getState().common.multiplayerSessionId || '',
    });

    setTimeout(() => sendMessage('ResultView', 'GemAddedToInventory'), 2500);
  };

  window.showBlockingError = (errorMessage: string) => {
    console.error('JS:' + errorMessage);
    setBlockingError(errorMessage);
  };

  window.openQuiz = (levelNumber: number, arraysCreated: number, quizQuestionData: string, pngData: string) => {
    dimScreen();
    store.dispatch(setLevelsCompleted(levelNumber));
    incrementArraysCreated(arraysCreated);

    // TODO
    puzzleActivityEvents.publish<EndPuzzleLevelEventData>(BusEventType.EndLevel, {
      activity: ActivityId.ArrayCrew,
      levelIndex: levelNumber,
      levelId: levelNumber.toString(),
      playSessionId: store.getState().common.multiplayerSessionId || '',
    });

    const questionData: ArrayPuzzleQuizQuestionData = JSON.parse(quizQuestionData);
    const creationData: ArrayPuzzleQuizCreationData = {
      levelNumber: levelNumber,
      questionData: questionData,
      pngData: pngData,
    };
    store.dispatch(setQuizCreationData(creationData));
    setTimeout(() => store.dispatch(setQuizPanelActive(true)), 2500);
  };

  useEffect(() => {
    return () => {
      detachAndUnloadImmediate().catch((reason) => {
        console.log(reason);
      });
    };
  }, [detachAndUnloadImmediate]);

  // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.
  const loadingPercentage = Math.round(loadingProgression * 100);

  return (
    <div className="unity-container">
      {blockingError && <BlockingError title={'Error'} errorMessage={blockingError} />}
      {!isLoaded && (
        // We'll conditionally render the loading overlay-controller if the Unity
        // Application is not loaded.
        <div className="loading-overlay">
          <div className="unity-loading-bar">
            <div className="unity-logo">
              <img src={companyLogo} alt="DragonBox logo" />
              <div>
                <img src={gameLogo} alt="Game logo" />
              </div>
            </div>
            <div className="progress-content">
              <div className="progress-text">({loadingPercentage}%)</div>
              <div className="spinner" />
            </div>
          </div>
        </div>
      )}
      <Unity className="unity" unityProvider={unityProvider} devicePixelRatio={devicePixelRatio} />
      <DebugButtons />
    </div>
  );
};
