import './Spinner.scss';

interface SpinnerProps {
  pageColor: string;
  spinnerColor: string;
}

export const Spinner = ({ pageColor, spinnerColor }: SpinnerProps) => {
  return (
    <div className="spinner-page" style={{ backgroundColor: pageColor }}>
      <div className="spinner-container">
        <div className="spinner" style={{ color: spinnerColor }}>
          Loading ...
        </div>
      </div>
    </div>
  );
};
