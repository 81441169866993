import { LabCard } from './lab-card';
import { PuzzleGame } from '../../config/puzzle-hub-config';

type LabsCategoryProps = {
  category: string;
  labs: PuzzleGame[];
};

export const LabCategory = ({ category }: { category: LabsCategoryProps }) => {
  return (
    <div className="puzzle-hub-category">
      {category.category && category.category != 'no-category' && (
        <h3 className="puzzle-hub-category-title">{category.category}</h3>
      )}
      <div className="puzzle-hub-category-labs">
        {category.labs.map((lab, index) => (
          <LabCard key={index} lab={lab} />
        ))}
      </div>
    </div>
  );
};
