import { useParams } from 'react-router-dom';
import { ActivityInfo, getActivityData } from './config/puzzle-hub-config';
import { ActivityHub } from './activity-hub/activity-hub';

export const PuzzleActivityHub = () => {
  const { activity } = useParams();
  const activityInfo: ActivityInfo = getActivityData(activity);

  return <ActivityHub {...activityInfo} />;
};
