// Temporary replacement for Kahoot's avatar system
import character1 from './images/Frame 1.svg';
import character2 from './images/Frame 2.svg';
import character3 from './images/Frame 3.svg';
import character4 from './images/Frame 4.svg';
import character5 from './images/Frame 5.svg';
import character6 from './images/Frame 6.svg';
import character7 from './images/Frame 7.svg';
import character8 from './images/Frame 8.svg';
import character9 from './images/Frame 9.svg';
import character10 from './images/Frame 10.svg';
import character11 from './images/Frame 11.svg';
import character12 from './images/Frame 12.svg';
import character13 from './images/Frame 13.svg';
import character14 from './images/Frame 14.svg';
import character15 from './images/Frame 15.svg';
import character16 from './images/Frame 16.svg';
import character17 from './images/Frame 17.svg';
import character18 from './images/Frame 18.svg';
import character19 from './images/Frame 19.svg';
import character20 from './images/Frame 20.svg';

const images = [
  character1,
  character2,
  character3,
  character4,
  character5,
  character6,
  character7,
  character8,
  character9,
  character10,
  character11,
  character12,
  character13,
  character14,
  character15,
  character16,
  character17,
  character18,
  character19,
  character20,
];

export const getRandomAvatar = (): string => images[Math.floor(Math.random() * images.length)];
