import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../../../../store';
import { QuizAnswerButton } from './quiz-answer-button';
import './quiz-container.scss';
import jojo from '../../../../../../assets/images/quiz/jojo_quiz_header.svg';
import {
  incrementCorrectQuizzes,
  ArrayPuzzleQuizCreationData,
  selectControllerQuizCreationData,
  selectQuizAttempts,
  setQuizAttempts,
  setQuizPanelActive,
} from './redux';
import { setHudDimActive } from '../activity/redux';
import { setSendGemPanelActive } from '../../../../game-mode/chest/components/chest-game-mode-component/redux';
import { puzzleActivityEvents } from '../../../events/puzzle-activity-events';
import { EndPuzzleLevelEventData } from '@puzzles/common/typedefs/puzzles/types';
import { ActivityId } from '@puzzles/core/types';
import { BusEventType } from '@puzzles/core/event-bus/constants';

export enum QuizContainerStatus {
  ANSWERING = 'answering',
  WRONG_ANSWER = 'wrong_answer',
  CORRECT_ANSWER = 'correct_answer',
}

export const QuizContainer = () => {
  const creationData = useSelector(selectControllerQuizCreationData);
  const [randomizedButtonsList, setRandomizedButtonsList] = useState<string[]>([]);
  const [screenshotRotations, setScreenshotRotations] = useState<number[]>([]);
  const [status, setStatus] = useState<QuizContainerStatus>(QuizContainerStatus.ANSWERING);
  const quizAttempts = useSelector(selectQuizAttempts);

  //initialization / constructor / mount code
  useEffect(() => {
    if (creationData === null || creationData.questionData.wrongAnswers.length == 0) {
      console.error('empty quiz data. Add it in the level editor');
      return;
    }
    randomizeButtons(creationData);
    randomizetScreenshotRotations(creationData);
  }, []);

  const randomizeButtons = (creationData: ArrayPuzzleQuizCreationData) => {
    //randomize correct/wrong answers position
    const randomButtonsArray: string[] = [];
    randomButtonsArray.push(creationData.questionData.correctAnswer);
    creationData.questionData.wrongAnswers.forEach((wrongAnswer) => randomButtonsArray.push(wrongAnswer));

    //shuffle array
    randomButtonsArray.sort(() => Math.random() - 0.5);
    setRandomizedButtonsList(randomButtonsArray);
  };

  const randomizetScreenshotRotations = (creationData: ArrayPuzzleQuizCreationData) => {
    //create stack of screenshots with random rotations
    const angles: number[] = [];
    let screenshotsCount = creationData?.levelNumber / 4; //1 screenshot every 4 levels
    screenshotsCount = Math.min(screenshotsCount, 5); //no more than 5 screenshots in the stack, otherwise it looks too busy and slows down rendering

    for (let i = 0; i < screenshotsCount; i++) angles.push(-8 + Math.random() * 16); // random range between [-8 , 8]

    setScreenshotRotations(angles);
  };

  const handleAnswerButtonClick = (correctAnswerClicked: boolean) => {
    const newAttempts = quizAttempts + 1;
    store.dispatch(setQuizAttempts(newAttempts));
    if (correctAnswerClicked) {
      setStatus(QuizContainerStatus.CORRECT_ANSWER);
      const delayBeforeAnimatingCoins = 700;
      setTimeout(() => {
        //4 gems if correct first attempt, 2 gems if second attempt, 1 gems if third attempt, 1 gems on fourth attempt
        let gemsToAddCount = 4;
        switch (newAttempts) {
          case 1:
            store.dispatch(incrementCorrectQuizzes());
            break;
          case 2:
            gemsToAddCount = 2;
            break;
          case 3:
            gemsToAddCount = 1;
            break;
          default:
            gemsToAddCount = 1;
            break;
        }

        store.dispatch(setQuizAttempts(0));

        if (creationData !== null) {
          puzzleActivityEvents.publish<EndPuzzleLevelEventData>(BusEventType.EndLevel, {
            activity: ActivityId.ArrayCrew,
            levelIndex: creationData.levelNumber,
            playSessionId: 'unknown',
            levelId: 'unknown',
          });
        }

        const coinAnimationMinimumDuration = 2000;

        setTimeout(
          () => store.dispatch(setQuizPanelActive(false)),
          coinAnimationMinimumDuration + gemsToAddCount * 1200
        );
        // TODO for now the below works but it isn't production code and we can't do a proper transition, we need to fix the structure of how overlays change
        setTimeout(
          () => store.dispatch(setSendGemPanelActive(true)),
          coinAnimationMinimumDuration + 1 + gemsToAddCount * 200
        );

        store.dispatch(setHudDimActive(true));
        setTimeout(() => {
          store.dispatch(setHudDimActive(false));
        }, 2500);
      }, delayBeforeAnimatingCoins);
    } else {
      setStatus(QuizContainerStatus.WRONG_ANSWER);
      setTimeout(() => setStatus(QuizContainerStatus.ANSWERING), 2000);
    }
  };

  return (
    <div className="quiz-container">
      <div className="backgroundSquare"></div>
      <div className="backgroundCircle"></div>
      <div className="questionHeader">
        <img src={jojo} alt="Jojo" />
        <h1>{creationData?.questionData?.question}</h1>
      </div>
      <div className="centralPanel">
        <div className="screenshotPanel">
          {screenshotRotations.map((angle, i) => (
            <div
              key={i}
              style={{ transform: 'rotate(' + angle + 'deg) translate(10vw,-50%)' }}
              className={'screenshot ' + (status == QuizContainerStatus.ANSWERING ? 'fadeIn' : 'fadeOut')}
            >
              <img src={creationData?.pngData} alt="Level" />
            </div>
          ))}
          <p className={status == QuizContainerStatus.WRONG_ANSWER ? 'fadeIn' : 'fadeOut'}> Try Again!</p>
          <p className={status == QuizContainerStatus.CORRECT_ANSWER ? 'fadeIn' : 'fadeOut'}>Well done!</p>
        </div>
        <div className="buttonsPanel">
          <div className="buttonsBox">
            {randomizedButtonsList.map((answer, i) => (
              <QuizAnswerButton
                key={i}
                text={answer}
                onClick={handleAnswerButtonClick}
                quizStatus={status}
                isCorrectAnswer={answer === creationData?.questionData.correctAnswer}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
