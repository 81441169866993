import {
  HostActivityClickOnPrefix,
  HostActivityPageViewSuffix,
  HostActivityThumbnailSuffix,
  HostActivityVideoClickedSuffix,
} from './constants';
import { ActivityClickOnThumbnailEvent, ActivityClickOnVideoEvent, ActivityPageViewEvent } from './types';

/**
 * Gets the event name for clicking on an activity thumbnail.
 * @param activityId Target activity ID (like "pixel-puzzle")
 * @example
 * ```ts
 * const event = getActivityClickOnThumbnailEventName('pixel-puzzle');
 * // event === 'Click on pixel-puzzle Thumbnail'
 * ```
 */
export const getActivityClickOnThumbnailEventName = (
  activityId = 'unknown'
): ActivityClickOnThumbnailEvent['eventName'] =>
  `${HostActivityClickOnPrefix}${activityId}${HostActivityThumbnailSuffix}`;

/**
 * Gets the event name for clicking on an activity video.
 * @param activityId Target activity ID (like "pixel-puzzle")
 * @example
 * ```ts
 * const event = getActivityPageViewEventName('pixel-puzzle');
 * // event === 'pixel-puzzle Page view'
 * ```
 */
export const getActivityPageViewEventName = (activityId = 'unknown'): ActivityPageViewEvent['eventName'] =>
  `${activityId}${HostActivityPageViewSuffix}`;

/**
 * Gets the event name for clicking on an activity video.
 * @param activityId Target activity ID (like "pixel-puzzle")
 * @example
 * ```ts
 * const event = getActivityVideoClickedEventName('pixel-puzzle');
 * // event === 'pixel-puzzle Video Clicked'
 * ```
 */
export const getActivityVideoClickedEventName = (activityId = 'unknown'): ActivityClickOnVideoEvent['eventName'] =>
  `${activityId}${HostActivityVideoClickedSuffix}`;
