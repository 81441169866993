import gem00 from './gem_0.png';
import gem01 from './gem_1.png';
import gem02 from './gem_2.png';
import gem03 from './gem_3.png';
import gem04 from './gem_4.png';
import gem05 from './gem_5.png';

import gemstatic00 from '../../../../../../player/features/game-mode/chest/img/coin0.png';
import gemstatic01 from '../../../../../../player/features/game-mode/chest/img/coin1.png';
import gemstatic02 from '../../../../../../player/features/game-mode/chest/img/coin2.png';
import gemstatic03 from '../../../../../../player/features/game-mode/chest/img/coin3.png';
import gemstatic04 from '../../../../../../player/features/game-mode/chest/img/coin4.png';
import gemstatic05 from '../../../../../../player/features/game-mode/chest/img/coin5.png';

export const GemTypes = [gem00, gem01, gem02, gem03, gem04, gem05];
export const GemWidthsOnInventory = [9, 9, 9, 15, 14, 12]; //depends on the width on the css class of this gem (width - margin-right)
export const GemSpriteFrameWidths = [89, 89, 89, 71, 138, 97];
export const GemSpriteFrameHeights = [97, 97, 97, 97, 97, 97];

export const GemTypesStatic = [gemstatic00, gemstatic01, gemstatic02, gemstatic03, gemstatic04, gemstatic05];
