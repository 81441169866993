import React from 'react';

interface SoloModeTitleProps {
  title: string;
  subtitle: string;
}

export const SoloModeTitle: React.FC<SoloModeTitleProps> = ({ title, subtitle }) => {
  return (
    <>
      <h1 className="solo-mode-title" dangerouslySetInnerHTML={{ __html: title }}></h1>
      <h2 className="solo-mode-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></h2>
    </>
  );
};
