import './inventory.scss';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { useEffect, useLayoutEffect, useRef } from 'react';
import Spritesheet from '../../../../../../common/lib/spritesheet/spritesheet-lib';
import { useSelector } from 'react-redux';
import { store } from '../../../../../../store';
import { selectControllerEndCountdown } from '../../../../puzzle-controller/redux';
import { GemProgression } from './gem-progression';
import { GemWidthsOnInventory, GemSpriteFrameHeights, GemSpriteFrameWidths, GemTypes } from './gem-types';
import { addControllerInventoryItem, PuzzleControllerInventoryItem } from './redux';
import { selectSendGemPanelActive } from '../chest-game-mode-component/redux';
import { puzzleActivityEvents } from '../../../../activity/events/puzzle-activity-events';
import { EndMultiplicationLevelEventData } from '@puzzles/common/typedefs/puzzles/types';
import { selectGameActivity } from '@puzzles/common/redux';
import { BusEventType } from '@puzzles/core/event-bus/constants';

gsap.registerPlugin(MotionPathPlugin);

type InventoryProps = {
  inventory: PuzzleControllerInventoryItem[];
};

type GemProps = {
  gemType: number;
  image: string;
  widthFrame: number;
  heightFrame: number;
  inventoryWidth: number;
  animate: boolean;
  animateIndex: number;
  onAnimationStart: () => void;
};

export const addToInventory = (levelIndex: number, quantity: number) => {
  const gemProgression: GemProgression = new GemProgression();

  for (let i = 0; i < quantity; i++) {
    const gemType: number = gemProgression.GetRandomGemForLevel(levelIndex);
    store.dispatch(addControllerInventoryItem(gemType));
  }
};

export const Gem = (props: GemProps) => {
  const boxRef = useRef<Spritesheet>(null);
  const boxAnimation = useRef({
    initialized: false,
  });

  const onSpriteSheetInitialized = (spritesheet: Spritesheet) => {
    if (boxRef.current != null && boxAnimation.current.initialized != true) {
      //this is to avoid animating (or setting visibility) twice when the component is mounted twice
      boxAnimation.current.initialized = true;

      //animate inventory expanding width to make room for the new gem
      gsap.to('.user-inventory', {
        width: props.inventoryWidth,
        duration: 0.1,
        ease: 'Expo.easeInOut',
      });

      const boxRefCurrent: any = boxRef.current;

      if (!props.animate) {
        //don't animate, just change opacity to make gem visible (used for initialization of inventory that already contains some gems)
        gsap.set(boxRefCurrent.spriteEl, {
          opacity: 1,
        });
        spritesheet.goToAndPause(1);
      } else {
        setTimeout(() => animateAppearingAndJumpingToInventory(spritesheet), props.animateIndex * 150);
      }
    }

    const animateAppearingAndJumpingToInventory = (spritesheet: Spritesheet) => {
      props.onAnimationStart();
      const timeline: gsap.core.Timeline = gsap.timeline();
      const durationMultiplier = 0.6;
      const boxRefCurrent: any = boxRef.current;

      spritesheet.play();

      //calculate x offsets to distribute right and left around the center
      let indexModified = Math.ceil(props.animateIndex / 2);
      indexModified *= props.animateIndex % 2 === 1 ? 1 : -1;
      const simultaneousGemsXOffset = indexModified * (window.innerWidth / 10);

      //get position to calculate trajectory from the center of the screen
      const gemRect = boxRefCurrent.spriteEl.getBoundingClientRect();
      const offsetFromScreenCenter = {
        x: window.innerWidth / 2 - gemRect.left + simultaneousGemsXOffset,
        y: window.innerHeight * 0.65 - gemRect.top,
      };

      //gem appears and jumps straight up as it grows in size

      if (boxRefCurrent.spriteEl) {
        timeline.fromTo(
          boxRefCurrent.spriteEl,
          {
            opacity: 1,
            x: offsetFromScreenCenter.x,
            y: offsetFromScreenCenter.y,
            scale: 0,
          },
          {
            y: offsetFromScreenCenter.y - window.innerHeight * 0.3,
            scale: 5,

            duration: 0.65 * durationMultiplier,
            ease: 'power2.out',
          }
        );

        //gem falls back to origin
        timeline.to(boxRefCurrent.spriteEl, {
          scale: 3,
          y: offsetFromScreenCenter.y,

          delay: 0.65,
          duration: 0.5 * durationMultiplier,
          ease: 'power2.in',
        });

        //gem jumps to score-view (and goes a bit beyond the end position, then starts scaling back and fitting into place)
        const centerToHudPath = [
          { x: offsetFromScreenCenter.x, y: offsetFromScreenCenter.y, rotation: 0, scale: 3 },
          {
            x: offsetFromScreenCenter.x * 0.75,
            y: offsetFromScreenCenter.y * 0.55,
            rotation: 60,
            scale: 3,
          },
          {
            x: offsetFromScreenCenter.x * 0.25,
            y: -offsetFromScreenCenter.y * 0.02,
            rotation: 60,
            scale: 3,
          },
          { x: 0, y: 0 - window.innerHeight * 0.02, rotation: 60, scale: 3 }, //goes a bit past the end position
          { x: 0, y: 0, rotation: 0, scale: 1 },
        ];

        timeline.to(boxRefCurrent.spriteEl, {
          duration: 1.1 * durationMultiplier,
          motionPath: {
            path: centerToHudPath,
          },
          ease: 'power3.inOut',
          onComplete: () => {
            spritesheet.goToAndPause(1);
          },
        });
      }
    };
  };

  return (
    <Spritesheet
      ref={boxRef}
      className={`gem gemType` + props.gemType}
      image={props.image}
      widthFrame={props.widthFrame}
      heightFrame={props.heightFrame}
      steps={5}
      fps={12}
      autoplay={false}
      loop={true}
      direction={'forward'}
      onInit={(spritesheet: Spritesheet) => {
        onSpriteSheetInitialized(spritesheet);
      }}
    />
  );
};

export const Inventory = (props: InventoryProps) => {
  const sendGemsPanelActive = useSelector(selectSendGemPanelActive);
  const gameEndCountdown = useSelector(selectControllerEndCountdown);
  const activeGemAnimations = useRef<number>(0); //using useRef instead of useState because useState triggers an unnecessary redraw when the variable is update
  const previousInventoryLength = useRef<number>(props.inventory.length); //using useRef instead of useState because useState triggers an unnecessary redraw when the variable is update
  const controllerActivity = useSelector(selectGameActivity);

  useEffect(() => {
    const onEndLevel = puzzleActivityEvents.subscribe<EndMultiplicationLevelEventData>(
      BusEventType.EndLevel,
      (data) => {
        // Temp for changing gems for fractions only. Activity config should be responsible for ths.
        switch (controllerActivity) {
          case 'multiplication':
            addToInventory(data.levelIndex, 2);
            break;
          case 'fractions':
            addToInventory(data.levelIndex, 3);
            break;
          default:
            addToInventory(data.levelIndex, 2);
        }
      }
    );
    previousInventoryLength.current = props.inventory.length;
    return () => {
      puzzleActivityEvents.unsubscribe(BusEventType.EndLevel, onEndLevel);
    };
  }, []);

  useLayoutEffect(() => {
    if (sendGemsPanelActive) {
      const animation = gsap.to('.user-inventory .gem', {
        scale: 0,
        stagger: 0.01,
        overwrite: true,
        duration: 0.3,
        ease: 'Back.easeIn',
        onComplete: () => {
          gsap.to('.user-inventory', {
            width: 0,
            duration: 0.2,
            ease: 'Expo.easeInOut',
          });
        },
      });

      // function to execute at unmount
      return () => {
        animation.kill();
      };
    }
  }, [sendGemsPanelActive]);

  //after animating the new gems, update the amount of items in the inventory
  useEffect(() => {
    if (props.inventory.length <= 0) activeGemAnimations.current = 0;
    const animateTrue = props.inventory.length > previousInventoryLength.current; // animate only gems added during gameplay (not the ones that are already in the inventory at initialization time)
    if (animateTrue) activeGemAnimations.current += props.inventory.length - previousInventoryLength.current; //increase by the difference because sometimes multiple gems are added at the same time, e.g.:using the Fill Inventory test button

    previousInventoryLength.current = props.inventory.length;
  }, [props.inventory]);

  useEffect(() => {
    if (gameEndCountdown && props.inventory.length <= 0) addToInventory(1, 2);
  }, [gameEndCountdown]);

  const calculateInventoryWidth = (): number => {
    let width = 30;
    props.inventory.forEach((gem) => (width += GemWidthsOnInventory[gem.type]));
    return width;
  };

  const onGemAnimationStart = () => {
    activeGemAnimations.current--;
  };

  let gems = props.inventory.map((item, index) => (
    <Gem
      image={GemTypes[item.type]}
      gemType={item.type}
      widthFrame={GemSpriteFrameWidths[item.type]}
      heightFrame={GemSpriteFrameHeights[item.type]}
      inventoryWidth={calculateInventoryWidth()}
      key={index}
      animate={index >= previousInventoryLength.current}
      animateIndex={index - previousInventoryLength.current + activeGemAnimations.current}
      onAnimationStart={onGemAnimationStart}
    />
  ));

  if (gameEndCountdown) gems = [];

  return <div className="user-inventory">{gems}</div>;
};
