class GemProbablityPerLevel {
  public levelMax: number = 0;
  public percentageChangeByGemType: Array<number>;

  constructor(levelMax: number, percentageChangeByGemType: Array<number>) {
    this.levelMax = levelMax;
    this.percentageChangeByGemType = percentageChangeByGemType;
  }
}

export class GemProgression {
  public GemTypesCount: number = 6;

  public gemProbablities: Array<GemProbablityPerLevel> = [
    new GemProbablityPerLevel(3, [80, 20, 0, 0, 0, 0]),
    new GemProbablityPerLevel(6, [20, 80, 0, 0, 0, 0]),
    new GemProbablityPerLevel(9, [0, 80, 20, 0, 0, 0]),
    new GemProbablityPerLevel(12, [0, 20, 80, 0, 0, 0]),
    new GemProbablityPerLevel(15, [0, 0, 80, 20, 0, 0]),
    new GemProbablityPerLevel(18, [0, 0, 40, 60, 0, 0]),
    new GemProbablityPerLevel(21, [0, 0, 20, 80, 0, 0]),
    new GemProbablityPerLevel(24, [0, 0, 0, 80, 20, 0]),
    new GemProbablityPerLevel(27, [0, 0, 0, 40, 60, 0]),
    new GemProbablityPerLevel(30, [0, 0, 0, 20, 80, 0]),
    new GemProbablityPerLevel(Number.MAX_VALUE, [0, 0, 0, 0, 80, 20]),
  ];

  public GetRandomGemForLevel(level: number): number {
    const probabilityRow = this.gemProbablities.find((x) => level < x.levelMax);
    if (probabilityRow == undefined) {
      console.error("can't fund probabilities for level:" + level);
      return 0; //first  gem
    }

    //console.log("row loaded for level:"+level+"  probabilityRow:"+JSON.stringify(probabilityRow));

    const randomValue = Math.random() * 100;
    let cumulativeProbability = 0;

    //console.log("randomValue:"+randomValue);

    //go through all the columns of the table, until we find the column corresponding to the random value
    for (let gemType: number = 0; gemType < this.GemTypesCount; gemType++) {
      cumulativeProbability += probabilityRow.percentageChangeByGemType[gemType];
      //console.log(gemType+") cumulativeProbability:"+cumulativeProbability);

      if (randomValue < cumulativeProbability) return gemType;
    }
    return this.GemTypesCount - 1; //final gem
  }
}

export class GemProgressionTest {
  //test basic usage of GemProgression
  public TestGetRandomGemForLevel(): void {
    const gemProgression: GemProgression = new GemProgression();
    const gemType: number = gemProgression.GetRandomGemForLevel(12);
    console.log('gemType: ' + gemType);
  }

  //test with a large number of random observations to see if they observations match the probability table
  public TestGetRandomGemTable(): void {
    const gemProgression: GemProgression = new GemProgression();

    //generate a large number of random gems and then check if the probabilty matches the table
    const totalRuns: number = 1_000_000;
    let gem = 0;
    for (let level: number = 0; level < 31; level++) {
      console.log(` level ${level} -------------------------------------------`);
      const observations = new Array<number>(0, 0, 0, 0, 0, 0);
      for (let run: number = 0; run < totalRuns; run++) {
        gem = gemProgression.GetRandomGemForLevel(level);
        observations[gem]++;
      }
      for (let gemType: number = 0; gemType < 6; gemType++) {
        const percentage: number = Math.round((observations[gemType] / totalRuns) * 100);
        const probabilityTableRow = gemProgression.gemProbablities.find((x) => level < x.levelMax);
        if (probabilityTableRow == undefined) {
          console.error("can't find probabilityTableRow");
        } else {
          const probabilityTableRowIndex: number = gemProgression.gemProbablities.indexOf(probabilityTableRow);
          console.log(
            `gemType ${gemType}   observations: ${observations[gemType]}   percentageObserved: ${percentage}     percentageExpected ${gemProgression.gemProbablities[probabilityTableRowIndex].percentageChangeByGemType[gemType]}   probablityTableRowIndex:${probabilityTableRowIndex}`
          );
          console.assert(
            percentage == gemProgression.gemProbablities[probabilityTableRowIndex].percentageChangeByGemType[gemType]
          );
        }
      }
    }
  }
}
