import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectControllerGameStarted,
  selectResetGame,
  selectControllerEndCountdown,
} from '../../features/puzzle-controller/redux';
import { AvatarPreview } from '../avatar-preview/avatar-preview';
import { SendGemsComponent } from '../../features/game-mode/chest/components/send-gems/send-gems-component';
import { QuizContainer } from '../../features/activity/array-unity/components/quiz/quiz-container';
import { CSSTransition } from 'react-transition-group';

import './overlay-controller.scss';
import { store } from '../../../store';
import { selectQuizPanelActive } from '../../features/activity/array-unity/components/quiz/redux';
import { setBadgeActive } from '../badge/redux';
import {
  selectSendGemPanelActive,
  setSendGemPanelActive,
} from '../../features/game-mode/chest/components/chest-game-mode-component/redux';

const NO_OVERLAY = 'noOverlay';
const AVATAR_PREVIEW_OVERLAY = 'avatarPreview';
const SEND_GEMS_OVERLAY = 'sendGemsPanel';
const QUIZ_OVERLAY = 'quizPanel';

export const OverlayController = () => {
  const [overlayType, setOverlayType] = useState<string>(AVATAR_PREVIEW_OVERLAY);
  const gameStarted = useSelector(selectControllerGameStarted);
  const gameEndCountdown = useSelector(selectControllerEndCountdown);
  const quizPanelActive = useSelector(selectQuizPanelActive);
  const sendGemsPanelActive = useSelector(selectSendGemPanelActive);
  const resetGame = useSelector(selectResetGame);

  const avatarRef = useRef(null);
  const sendGemsRef = useRef(null);
  const quizRef = useRef(null);

  const [avatarInProp, setAvatarInProp] = useState(false);
  const [sendGemsInProp, setSendGemsInProp] = useState(false);
  const [quizInProp, setQuizInProp] = useState(false);

  const resetOverlayProps = () => {
    setAvatarInProp(false);
    setSendGemsInProp(false);
    setQuizInProp(false);
  };

  useEffect(() => {
    if (resetGame) {
      resetOverlayProps();
      store.dispatch(setBadgeActive(false));
      setAvatarInProp(true);
    }
  }, [resetGame]);

  useEffect(() => {
    resetOverlayProps();
    store.dispatch(setBadgeActive(false));
    setAvatarInProp(true);

    if (sendGemsPanelActive) {
      resetOverlayProps();
      setSendGemsInProp(true);
      setOverlayType(SEND_GEMS_OVERLAY);
    }

    if (quizPanelActive) {
      resetOverlayProps();
      setQuizInProp(true);
      setOverlayType(QUIZ_OVERLAY);
    }
  }, []);
  //
  useEffect(() => {
    // TODO this is becoming a little verbose so we should refactor.
    if (gameStarted && !sendGemsPanelActive && !quizPanelActive) {
      setTimeout(() => {
        store.dispatch(setBadgeActive(true));
        resetOverlayProps();
      }, 2000);
    }
  }, [gameStarted]);

  useEffect(() => {
    if (gameEndCountdown) {
      store.dispatch(setSendGemPanelActive(true));
    }
  }, [gameEndCountdown]);

  useEffect(() => {
    if (sendGemsPanelActive) {
      resetOverlayProps();
      setSendGemsInProp(true);
      setOverlayType(SEND_GEMS_OVERLAY);
    } else if (overlayType === SEND_GEMS_OVERLAY) {
      resetOverlayProps();
      setOverlayType(NO_OVERLAY);
    }
  }, [sendGemsPanelActive]);

  useEffect(() => {
    if (quizPanelActive) {
      //show quiz panel
      resetOverlayProps();
      setQuizInProp(true);
      setOverlayType(QUIZ_OVERLAY);
    } else if (overlayType === QUIZ_OVERLAY) {
      //hide quiz panel
      resetOverlayProps();
      setOverlayType(NO_OVERLAY);
    }
  }, [quizPanelActive]);

  return (
    <div className="overlays">
      <CSSTransition
        nodeRef={avatarRef}
        in={avatarInProp}
        timeout={200}
        classNames="overlay-avatar-container"
        unmountOnExit={true}
      >
        <span ref={avatarRef} className={`overlay-container`}>
          <AvatarPreview />
        </span>
      </CSSTransition>
      <CSSTransition
        nodeRef={sendGemsRef}
        in={sendGemsInProp}
        timeout={200}
        classNames="overlay-sendgems-container"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <span ref={sendGemsRef} className={`overlay-container`}>
          <SendGemsComponent />
        </span>
      </CSSTransition>
      <CSSTransition
        nodeRef={quizRef}
        in={quizInProp}
        timeout={200}
        classNames="overlay-quiz-container"
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <span ref={quizRef} className={`overlay-container`}>
          <QuizContainer />
        </span>
      </CSSTransition>
    </div>
  );
};
