import './blocking-error.scss';

type BlockingErrorProps = {
  errorMessage: string;
  title: string;
};

export const BlockingError = (props: BlockingErrorProps) => {
  return (
    <div className="device-support-error">
      <h3 className="device-support-error--msg">{props.title}</h3>
      <p>{props.errorMessage}</p>
    </div>
  );
};
