import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type UserBadgeProps = {
  username: string | null;
  avatar: string | undefined;
};

import defaultAvatar from '../../../common/user/avatar/images/blank.svg';
import './badge.scss';

import { selectControllerGameStarted } from '../../features/puzzle-controller/redux';
import { selectBadgeActive } from './redux';

export const Badge = (props: UserBadgeProps) => {
  const badgeActive = useSelector(selectBadgeActive);
  const gameStarted = useSelector(selectControllerGameStarted);
  const [badgeClass, setBadgeClass] = useState<string>('user-badge-container');

  const updateBadgeClass = () => {
    if (badgeActive && gameStarted) {
      setBadgeClass('user-badge-container user-badge-container-active');
    } else {
      setBadgeClass('user-badge-container');
    }
  };
  useEffect(() => {
    updateBadgeClass();
  }, []);

  useEffect(() => {
    updateBadgeClass();
  }, [badgeActive, gameStarted]);

  return (
    <div className={badgeClass}>
      <div className="user-badge">
        <img src={props.avatar || defaultAvatar} alt="Avatar" />
      </div>
    </div>
  );
};
