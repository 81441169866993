import { PausePanel } from '../../../../../components/pause-panel/pause-panel';
import { OverlayController } from '../../../../../components/overlay-controller/overlay-controller';
import { Hud } from '../hud/hud';
import { ChestGameModeConfig } from '../../../../../../app';
import { useContext, useEffect, useRef } from 'react';
import { puzzleActivityEvents } from '../../../../activity/events/puzzle-activity-events';
import { EndPuzzleLevelEventData } from '@puzzles/common/typedefs/puzzles/types';
import { store } from '../../../../../../store';
import { setSendGemPanelActive } from './redux';
import { useSelector } from 'react-redux';
import { selectControllerCID } from 'src/controller/features/puzzle-controller/redux';
import { GameServiceContext } from '@puzzles/common/state/game-service';
import { BusEventType } from '@puzzles/core/event-bus/constants';

export const ChestGameModeComponent = (props: ChestGameModeConfig) => {
  const levelsCompleted = useRef<number>(0);
  const { controllerClient } = useContext(GameServiceContext);
  const controllerCID = useSelector(selectControllerCID);
  const onEndLevel = () => {
    if (props.quiz) return;
    levelsCompleted.current++;
    // This is not very scalable right now. A temporary solution for the beta. We'll implement proper reports/stats later.
    controllerClient.sendToPlayer(
      JSON.stringify({
        type: 'UpdateFractionsStats',
        payload: {
          cid: controllerCID,
        },
      })
    );
    if (props.levelsUntilSendGems && levelsCompleted.current >= props.levelsUntilSendGems) {
      setTimeout(() => {
        store.dispatch(setSendGemPanelActive(true));
      }, 2000);
      levelsCompleted.current = 0;
    }
  };

  useEffect(() => {
    const endLevelEventSubscription = puzzleActivityEvents.subscribe<EndPuzzleLevelEventData>(
      BusEventType.EndLevel,
      () => onEndLevel()
    );
    return () => {
      puzzleActivityEvents.unsubscribe(BusEventType.EndLevel, endLevelEventSubscription);
    };
  }, []);

  // TODO move other Chest Mode higher level logic here

  return (
    <div>
      <PausePanel />
      <OverlayController />
      <Hud />
    </div>
  );
};
