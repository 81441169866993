import { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { JoinSession } from './controller/features/join-session/join-session';
import { gameServiceClients, GameServiceContext } from './common/state/game-service';
import { OfflineModal } from './common/components/offline-modal/offline-modal';
import { PuzzleMainHub } from './player/features/puzzle-main-hub/puzzle-main-hub';
import { Spinner } from './common/components/UI/spinner/loading-spinner';
import { BuildVersion } from './common/components/show-build-version/build-version';
import { routes as controllerRoutes } from './controller/features/routes.constants';
import { routes as playerRoutes } from './player/features/routes.constants';
import { routes as testRoutes } from './tester/routes.constants';
import { routes as skoleRoutes } from './skole/features/routes.constants';
import { ChestGameModeComponent } from './controller/features/game-mode/chest/components/chest-game-mode-component/chest-game-mode-component';
import { ArrayActivityComponent } from './controller/features/activity/array-unity/components/activity/array-activity-component';
import { FractionsActivityComponent } from './controller/features/activity/fractions/components/activity/fractions-activity-component';
import { PuzzleActivityHub } from './player/features/puzzle-activity-hub/puzzle-activity-hub';
import { NotFound } from './404';
import { AmplitudeApiKey, initializeAmplitude } from './core/amplitude';
import { SoloModeHub } from './player/features/solo-mode-activity/solo-mode-hub';

export type GameModeConfig<T> = T;

export type PuzzleControllerConfig<T = {}> = {
  activity: React.ComponentType;
  gameMode: React.ComponentType<T>;
  gameModeConfig: GameModeConfig<T>;
  supportedOrientations: string[];
  mobileSupported?: boolean;
};

export type ChestGameModeConfig = {
  quiz?: boolean;
  levelsUntilSendGems?: number;
};

const multiplicationGameModeConfig: ChestGameModeConfig = {
  quiz: true,
  levelsUntilSendGems: 3,
};

const fractionGameModeConfig: ChestGameModeConfig = {
  quiz: false,
  levelsUntilSendGems: 3,
};

const multiplicationControllerConfig: PuzzleControllerConfig<ChestGameModeConfig> = {
  activity: ArrayActivityComponent,
  gameMode: ChestGameModeComponent,
  gameModeConfig: multiplicationGameModeConfig,
  supportedOrientations: ['landscape'],
  mobileSupported: false,
};

const fractionsControllerConfig: PuzzleControllerConfig<ChestGameModeConfig> = {
  activity: FractionsActivityComponent,
  gameMode: ChestGameModeComponent,
  gameModeConfig: fractionGameModeConfig,
  supportedOrientations: ['landscape', 'portrait'],
  mobileSupported: true,
};

export const App = () => {
  const [online, isOnline] = useState(navigator.onLine);

  const setOnline = () => {
    console.log('We are online!');
    isOnline(true);
  };
  const setOffline = () => {
    console.log('We are offline!');
    isOnline(false);
  };

  useEffect(() => {
    initializeAmplitude(AmplitudeApiKey);

    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount => pure component
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  // Multiplayer Routes

  const PlayerContainer = lazy(() =>
    import('./player/features/puzzle-player/puzzle-player').then((module) => ({
      default: module.PuzzlePlayer,
    }))
  );
  const PuzzleController = lazy(() =>
    import('./controller/features/puzzle-controller/puzzle-controller').then((module) => ({
      default: module.PuzzleController,
    }))
  );

  const Tester = lazy(() => import('./tester/test-page').then((module) => ({ default: module.TestPage })));

  const ControllerTester = lazy(() =>
    import('./tester/controller-tester').then((module) => ({
      default: module.ControllerTester,
    }))
  );

  const PublicTest = lazy(() =>
    import('./tester/public-test').then((module) => ({
      default: module.PublicTest,
    }))
  );

  // Nooms Routes
  const NoomActivityComponent = lazy(() =>
    import('./nooms/NoomActivityComponent').then((module) => ({
      default: module.NoomActivityComponent,
    }))
  );

  const NoomPuzzleActivityComponent = lazy(() =>
    import('./noomPixelPuzzle/NoomPixelPuzzleActivityComponent').then((module) => ({
      default: module.NoomPixelPuzzleActivityComponent,
    }))
  );

  // Oslo Project Routes

  const PilotContainer = lazy(() =>
    import('./skole/features/pilot-container/PilotContainer').then((module) => ({
      default: module.PilotContainer,
    }))
  );

  const PilotCurriculum = lazy(() =>
    import('./skole/features/pilot-container/PilotCurriculum').then((module) => ({
      default: module.PilotCurriculum,
    }))
  );

  const PilotSupplement = lazy(() =>
    import('./skole/features/pilot-container/PilotSupplement').then((module) => ({
      default: module.PilotSupplement,
    }))
  );

  const TestRoute = () => {
    if (import.meta.env.VITE_DEBUG_MODE_ENABLED === 'true') {
      return (
        <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
          <Tester />
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
        <PublicTest />
      </Suspense>
    );
  };

  const ControllerTestRoute = () => {
    if (import.meta.env.VITE_DEBUG_MODE_ENABLED === 'true') {
      return (
        <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
          <ControllerTester />
        </Suspense>
      );
    }
    return (
      <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
        <JoinSession />
      </Suspense>
    );
  };

  return (
    <GameServiceContext.Provider value={gameServiceClients}>
      <BuildVersion />
      <OfflineModal display={!online} />
      <Router basename="/">
        <Routes>
          <Route path={controllerRoutes.LOGIN_GAME_ID} element={<JoinSession />} />
          <Route path={controllerRoutes.JOIN} element={<JoinSession />} />
          <Route
            path={playerRoutes.HUB}
            element={
              <Suspense fallback={<>...</>}>
                <PuzzleMainHub />
              </Suspense>
            }
          />
          <Route path={playerRoutes.PUZZLE_HUB} element={<PuzzleActivityHub />} />

          <Route path={playerRoutes.PUZZLE_HUB_SOLO} element={<SoloModeHub />} />

          <Route
            path="/noom"
            element={
              <Suspense fallback={<>...</>}>
                <NoomActivityComponent />
              </Suspense>
            }
          />
          <Route
            path="/fractions"
            element={
              <Suspense fallback={<>...</>}>
                <FractionsActivityComponent />
              </Suspense>
            }
          />
          <Route
            path="/noomPixelPuzzle"
            element={
              <Suspense fallback={<>...</>}>
                <NoomPuzzleActivityComponent />
              </Suspense>
            }
          />
          <Route
            path={playerRoutes.PUZZLE_LOBBY}
            element={
              <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
                <PlayerContainer />
              </Suspense>
            }
          />

          <Route
            path={controllerRoutes.MULTIPLICATION} //
            element={
              <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
                <PuzzleController config={multiplicationControllerConfig} />
              </Suspense>
            }
          />

          <Route
            path={controllerRoutes.FRACTIONS} //
            element={
              <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
                <PuzzleController config={fractionsControllerConfig} />
              </Suspense>
            }
          />

          {/*Skole Pages */}
          <Route
            path={skoleRoutes.HOME}
            element={
              <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
                <PilotContainer />
              </Suspense>
            }
          />
          <Route
            path={skoleRoutes.FULL_CURRICULUM}
            element={
              <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
                <PilotCurriculum />
              </Suspense>
            }
          />
          <Route
            path={skoleRoutes.PICK_AND_CHOOSE}
            element={
              <Suspense fallback={<Spinner pageColor="#3b1e7c" spinnerColor="white" />}>
                <PilotSupplement />
              </Suspense>
            }
          />

          {/*Tests - Development Environment Only */}

          <Route path={testRoutes.TEST_PLAYLIST} element={<TestRoute />} />
          <Route path={testRoutes.AI_CONTROLLER} element={<ControllerTestRoute />} />

          {/* Handle Errors */}
          <Route path={controllerRoutes.NOT_FOUND} element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </GameServiceContext.Provider>
  );
};
