import { DuckFractionsSoloClickFractionThumbnail } from '@puzzles/core/amplitude';
import { SoloModePuzzle } from '../../config/solo-mode-activity-config';
import { trackEvent } from '@puzzles/core/amplitude';
import { useEffect, useRef } from 'react';
import iconStar from '../../assets/icon-star.svg';
import iconLock from '../../assets/icon-lock.svg';

interface PuzzleProps {
  puzzle: SoloModePuzzle;
  status: boolean;
  completedPlaylistArray: string[];
}

const Puzzle: React.FC<PuzzleProps> = ({ puzzle, status, completedPlaylistArray }: PuzzleProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyLink = entry.target as HTMLAnchorElement;
          lazyLink.style.backgroundImage = `url(${lazyLink.dataset.background})`;
          observer.unobserve(lazyLink);
        }
      });
    });

    if (linkRef.current) {
      observer.observe(linkRef.current);
    }

    return () => {
      if (linkRef.current) {
        observer.unobserve(linkRef.current);
      }
    };
  }, []);

  const handleClick = () => {
    trackEvent({
      eventName: DuckFractionsSoloClickFractionThumbnail,
      play_session_id: puzzle.playlist,
    });
  };

  return (
    <div className="solo-mode-puzzle">
      <a
        href={puzzle.url}
        ref={linkRef}
        onClick={handleClick}
        title={puzzle.playlist}
        className={`solo-mode-puzzle-bg ${status ? 'unlocked' : 'locked'}`}
        data-background={puzzle.background}
      >
        {status ? (
          // unlocked playlist
          completedPlaylistArray.includes(puzzle.playlist) && (
            // completed playlist
            <img src={iconStar} alt={`level ${puzzle.playlist} unlocked`} className="unlocked-icon" loading="lazy" />
          )
        ) : (
          // locked playlist
          <img src={iconLock} alt={`level ${puzzle.playlist} unlocked`} className="locked-icon" loading="lazy" />
        )}
      </a>
    </div>
  );
};

export default Puzzle;
