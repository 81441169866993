import { useEffect, useState } from 'react';
import './build-version.scss';

export const BuildVersion = () => {
  const [environment, setEnvironment] = useState<string>('');
  const [version, setVersion] = useState<string>('');

  useEffect(() => {
    void fetch('/build.json')
      .then((response) => response.json())
      .then((data) => {
        setEnvironment(data.environment);
        setVersion(data.build_version);
      });
  }, []);

  const copyToClipboard = () => {
    void navigator.clipboard.writeText(version);
  };

  return (
    <div className="build-version">
      {environment && (
        <>
          <button className="button" onClick={copyToClipboard}>
            <svg viewBox="0 0 32 32" focusable="false" stroke="none" strokeWidth="0">
              <path d="M15,22 L17,22 L17,20 L15,20 L15,22 Z M16,6 C10.48,6 6,10.48 6,16 C6,21.52 10.48,26 16,26 C21.52,26 26,21.52 26,16 C26,10.48 21.52,6 16,6 Z M16,24 C11.59,24 8,20.41 8,16 C8,11.59 11.59,8 16,8 C20.41,8 24,11.59 24,16 C24,20.41 20.41,24 16,24 Z M16,10 C13.79,10 12,11.79 12,14 L14,14 C14,12.9 14.9,12 16,12 C17.1,12 18,12.9 18,14 C18,16 15,15.75 15,19 L17,19 C17,16.75 20,16.5 20,14 C20,11.79 18.21,10 16,10 Z"></path>
            </svg>
          </button>
          <div className="details">
            <span className="environment">{environment}</span>
            <span className="build">{version}</span>
          </div>
        </>
      )}
    </div>
  );
};
