import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './hud.scss';
import { Badge } from '../../../../../components/badge/badge';
import { selectControllerAvatar, selectControllerNickname } from '../../../../puzzle-controller/redux';
import { Inventory } from '../inventory/inventory';
import { selectControllerInventory } from '../inventory/redux';
import { selectHudDimActive } from '../../../../activity/array-unity/components/activity/redux';

export const Hud = () => {
  const controllerNickname = useSelector(selectControllerNickname);
  const controllerAvatar = useSelector(selectControllerAvatar);
  const controllerInventory = useSelector(selectControllerInventory);
  const hudDimActive = useSelector(selectHudDimActive);
  const [hudClasses, setHudClasses] = useState<string>('hud-container');

  useEffect(() => {
    if (hudDimActive) {
      setHudClasses('hud-container dim');
    } else {
      setHudClasses('hud-container');
    }
  }, [hudDimActive]);

  return (
    <div className={hudClasses}>
      <Badge username={controllerNickname} avatar={controllerAvatar} />
      <Inventory inventory={controllerInventory} />
    </div>
  );
};
