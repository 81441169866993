import { QuizContainerStatus } from './quiz-container';
import './quiz-answer-button.scss';
import { useState } from 'react';

interface ClickHandler {
  (correctAnswerClicked: boolean): void;
}

type QuizAnswerButtonProps = {
  text: string;
  onClick: ClickHandler;
  quizStatus: QuizContainerStatus;
  isCorrectAnswer: boolean;
};

export const QuizAnswerButton = (props: QuizAnswerButtonProps) => {
  const [alreadyClicked, setAlreadyClicked] = useState<boolean>(false);

  const handleClick = () => {
    if (props.quizStatus != QuizContainerStatus.ANSWERING) return;

    setAlreadyClicked(true);
    props.onClick(props.isCorrectAnswer);
  };

  const getButtonClassBasedOnStatus = () => {
    switch (props.quizStatus) {
      case QuizContainerStatus.ANSWERING:
        return 'quizButton buttonStateAnswering';

      case QuizContainerStatus.WRONG_ANSWER:
      case QuizContainerStatus.CORRECT_ANSWER:
        if (alreadyClicked) {
          if (props.isCorrectAnswer) return 'quizButton buttonStateCorrectAnswer';
          else return 'quizButton buttonStateWrongAnswer';
        } else {
          return 'quizButton buttonStateNotClicked';
        }
    }
  };

  return (
    <button className={getButtonClassBasedOnStatus()} onClick={handleClick}>
      {props.text}
    </button>
  );
};
