import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  gamePaused,
  selectControllerCID,
  selectControllerEndCountdown,
  selectControllerGameEnded,
  selectControllerNickname,
} from '../../../../puzzle-controller/redux';
import { SendGemsActivity } from './send-gems-activity';
import { store } from '../../../../../../store';
import { GameServiceContext } from '../../../../../../common/state/game-service';

import './send-gems.scss';
import { removeControllerInventoryItems, selectControllerInventory } from '../inventory/redux';
import { setBadgeActive } from '../../../../../components/badge/redux';
import { setSendGemPanelActive } from '../chest-game-mode-component/redux';

export const SendGemsComponent = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const pixiStage = useRef<SendGemsActivity | null>(null);
  const gameEnded = useSelector(selectControllerGameEnded);
  const gameEndCountdown = useSelector(selectControllerEndCountdown);
  const controllerNickname = useSelector(selectControllerNickname);
  const controllerInventory = useSelector(selectControllerInventory);
  const { controllerClient } = useContext(GameServiceContext);
  const [firstSend, setFirstSend] = useState<boolean>(true);
  const controllerCID = useSelector(selectControllerCID);
  const [pixiStageReady, setPixiStageReady] = useState(false);
  const endGameTimeout = useRef<number>();

  const showAvatarInPlayer = (show: boolean) => {
    controllerClient.sendToPlayer(
      JSON.stringify({
        type: 'ToggleVisibilityInPlayer',
        payload: {
          cid: controllerCID,
          visibleInPlayer: show,
        },
      })
    );
  };

  useEffect(() => {
    resetButton();
  }, []);

  useEffect(() => {
    if (pixiStageReady) {
      controllerInventory.map((item) => pixiStage.current?.addGem(item.type));
    }
  }, [pixiStageReady]);

  // Add Pixi
  useEffect(() => {
    if (ref.current && !pixiStage.current) {
      store.dispatch(setBadgeActive(false));
      showAvatarInPlayer(true);
      pixiStage.current = new SendGemsActivity(ref.current);
      setPixiStageReady(true);
    }
    return () => {
      store.dispatch(setBadgeActive(true));
      showAvatarInPlayer(false);
      pixiStage.current?.dispose();
      pixiStage.current = null;
      setPixiStageReady(false);
    };
  }, [ref]);

  useEffect(() => {
    // Game ended behaviour
    if (gameEndCountdown) {
      endGameTimeout.current = setTimeout(() => {
        if (controllerInventory.length > 0) {
          pixiStage.current?.sendAllGems();
          sendGemsToPlayer(true);
          clearTimeout(showRemindTimeout.current);
          pixiStage.current?.hideRemindAnimation();
        }
      }, 5000);
    }

    return () => {
      clearTimeout(endGameTimeout.current);
    };
  }, [gameEndCountdown]);

  useEffect(() => {
    if (gameEndCountdown && controllerInventory.length <= 0) {
      clearTimeout(endGameTimeout.current);
      clearTimeout(showRemindTimeout.current);
      store.dispatch(gamePaused(true));
      pixiStage.current?.hideRemindAnimation();
    }

    // Game active behaviour
    if (controllerInventory.length <= 0) {
      clearTimeout(showRemindTimeout.current);
      setTimeout(() => {
        store.dispatch(setSendGemPanelActive(false));
      }, 1500);
    }
  }, [controllerInventory]);

  const sendGemsToPlayer = (sendAll = false, initialDelay = 0) => {
    if (controllerInventory.length == 0) return;

    const gemAmount = sendAll ? controllerInventory.length : 1;
    let gemTypes: number[];

    if (sendAll) {
      gemTypes = controllerInventory.map((gem) => gem.type);
    } else {
      gemTypes = [controllerInventory[0].type];
    }

    if (gemAmount > controllerInventory.length) return;

    store.dispatch(removeControllerInventoryItems(gemAmount));

    setTimeout(() => {
      controllerClient.sendToPlayer(
        JSON.stringify({
          type: 'CollectGems',
          payload: {
            nickname: controllerNickname,
            gemTypes: gemTypes,
          },
        })
      );
    }, initialDelay);
  };

  // ui
  const holdTriggerTimer = useRef<number>();
  const holdingInterval = useRef<number>();
  const holdingTimer = useRef<number>(0);
  const holding = useRef<boolean>(false);
  const buttonActive = useRef<boolean>(false);
  const releaseReady = useRef<boolean>(false);
  const showRemindTimeout = useRef<number>();

  const holdingHandler = () => {
    if (holdingInterval) clearInterval(holdingInterval.current);
    holdingInterval.current = setInterval(() => {
      if (releaseReady.current) return;

      if (!holding.current) pixiStage.current?.shakeAllGems();
      holding.current = true;
      if (holdingTimer.current >= 1000) {
        releaseReady.current = true;
        clearInterval(holdingInterval.current);
        pixiStage.current?.destroyShakeAllGems();
        pixiStage.current?.sendAllGems();
        sendGemsToPlayer(true, 700);
        pixiStage.current?.showLookUpCenter();
      }

      holdingTimer.current = holdingTimer.current + 100;
    }, 100);
  };

  const mouseDownHandler = () => {
    if (gameEnded && controllerInventory.length <= 0) return;
    if (pixiStage.current?.getAnimating) return;
    pixiStage.current?.hideRemindAnimation();
    clearTimeout(holdTriggerTimer.current);
    buttonActive.current = true;
    holdTriggerTimer.current = setTimeout(holdingHandler, 200);
  };

  const resetButton = () => {
    clearTimeout(holdTriggerTimer.current);
    clearInterval(holdingInterval.current);

    buttonActive.current = false;
    holding.current = false;
    releaseReady.current = false;
    holdingTimer.current = 0;
  };

  const reshowRemind = () => {
    clearTimeout(showRemindTimeout.current);
    showRemindTimeout.current = setTimeout(() => {
      pixiStage.current?.remindAnimation();
    }, 1500);
  };

  const mouseUpHandler = () => {
    if (buttonActive.current) {
      if (!releaseReady.current) {
        reshowRemind();
        pixiStage.current?.destroyShakeAllGems();
      }

      if (!holding.current) {
        pixiStage.current?.sendAGem();
        const delay = 400;
        if (firstSend && !gameEnded) pixiStage.current?.showLookUp();

        sendGemsToPlayer(false, delay);
        setFirstSend(false);
      }
    }
    resetButton();
  };

  return (
    <div ref={ref} className="send-gems-container" onPointerDown={mouseDownHandler} onPointerUp={mouseUpHandler}></div>
  );
};
