import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GameServiceContext } from '../../../common/state/game-service';
import { selectGameActivity, selectGamePin, selectGamePlaylist } from '../../../common/redux';
import {
  setControllerNickname,
  selectControllerNickname,
  selectControllerClientReady,
  setControllerGamePin,
  setControllerAvatar,
  selectGameJoinError,
  resetState,
  controllerClientReady,
  setGameJoinError,
  selectControllerCID,
} from '../puzzle-controller/redux';
import { BlockingError } from '../../../common/components/blocking-error/blocking-error';
import { checkDeviceSupport, DeviceSupport } from '../../../common/components/supported-devices/supported-devices';
import * as Sentry from '@sentry/browser';

import '../../../common/css/kahoot-theme.scss';
import '../puzzle-controller/puzzle-controller.scss';
import kahoot_logo from '../../../assets/images/kahoot_logo.svg';
import { store } from '../../../store';
import { getRandomAvatar } from '../../../common/user/avatar/random-avatar';
import { routes } from '../routes.constants';
import { resetState as resetInventoryState } from '../game-mode/chest/components/inventory/redux';
import { resetState as resetChestActivityState } from '../game-mode/chest/components/chest-game-mode-component/redux';
import { resetState as resetArrayActivityState } from '../activity/array-unity/components/activity/redux';
import { resetState as resetArrayQuizState } from '../activity/array-unity/components/quiz/redux';
import { resetState as resetBadgeState } from '../../components/badge/redux';

export const JoinSession = () => {
  const { controllerClient } = useContext(GameServiceContext);
  const controllerNickname = useSelector(selectControllerNickname);
  const isControllerClientReady = useSelector(selectControllerClientReady);
  const controllerCID: any = useSelector(selectControllerCID);
  const controllerPlaylist = useSelector(selectGamePlaylist);
  const existingGamePin = useSelector(selectGamePin);
  const navigate = useNavigate();
  const [btnClicked, setBtnClicked] = useState(false);
  const [isWaitingOnClientInit, setIsWaitingOnClientInit] = useState(false);
  const [gamePin, setGamePin] = useState(existingGamePin?.toString());
  const [nickname, setNickname] = useState(controllerNickname);
  const [isError, setIsError] = useState(false);
  const [joinText, setJoinText] = useState<string>('Join');
  const joinError = useSelector(selectGameJoinError);
  const isInputValid = gamePin && Number(gamePin) && nickname;
  const readyToStart = isWaitingOnClientInit && isControllerClientReady && controllerPlaylist;
  const deviceSupport: DeviceSupport = checkDeviceSupport();
  const controllerActivity = useSelector(selectGameActivity);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (joinError != '') {
      setIsError(true);
      setJoinText('Try again...');
    } else {
      setIsError(false);
    }
  }, [joinError]);

  const JoinError = () => {
    if (isError) {
      return (
        <div className="game-error">
          <p>
            <svg viewBox="0 0 32 32" focusable="false" stroke="none" strokeWidth="0">
              <path d="M16,6 C21.514,6 26,10.485 26,16 C26,21.513 21.514,26 16,26 C10.486,26 6,21.514 6,16 C6,10.485 10.486,6 16,6 Z M16,24 C20.411,24 24,20.411 24,16 C24,11.589 20.411,8 16,8 C11.589,8 8,11.589 8,16 C8,20.411 11.589,24 16,24 Z M16,18.75 C16.6903559,18.75 17.25,19.3096441 17.25,20 C17.25,20.6903559 16.6903559,21.25 16,21.25 C15.3096441,21.25 14.75,20.6903559 14.75,20 C14.75,19.3096441 15.3096441,18.75 16,18.75 Z M17,17 L15,17 L15,11 L17,11 L17,17 Z"></path>
            </svg>
            {joinError}
          </p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    // We should probably combine these resets, it's a lot.
    const hadCID = controllerCID;
    console.log('Reset');
    if (controllerCID) controllerClient.disconnect(controllerCID);
    store.dispatch(resetState());
    store.dispatch(resetInventoryState());
    store.dispatch(resetChestActivityState());
    store.dispatch(resetArrayActivityState());
    store.dispatch(resetArrayQuizState());
    store.dispatch(resetChestActivityState());
    store.dispatch(resetBadgeState());
    if (!deviceSupport.isSupported) Sentry.captureMessage(deviceSupport.errorMessage);
    // We force refresh because Unity WebGL's unload is buggy: https://github.com/jeffreylanters/react-unity-webgl/issues/22
    if (hadCID) window.location.reload();
  }, []);

  const onJoinBtnClicked = () => {
    setBtnClicked(true);
  };

  useEffect(() => {
    if (btnClicked) {
      setJoinText('Joining...');
    } else {
      setJoinText('Join');
    }
  }, [btnClicked]);

  useEffect(() => {
    if (readyToStart && controllerActivity) {
      console.log('Starting...');
      let devMode = '';
      if (searchParams.get('DevMode') === 'true') devMode = '&DevMode=true';
      switch (controllerActivity) {
        case 'multiplication':
          navigate(routes.MULTIPLICATION + '?multi=true&list=' + controllerPlaylist + devMode);
          break;
        case 'fractions':
          navigate(routes.FRACTIONS + '?multi=true&list=' + controllerPlaylist + devMode);
          break;
        default:
          navigate(routes.MULTIPLICATION + '?multi=true&list=' + controllerPlaylist + devMode);
      }
    }
  }, [readyToStart, controllerActivity]);

  useEffect(() => {
    if (btnClicked && isInputValid && !isWaitingOnClientInit) {
      console.log('Initiating...');
      const avatar = getRandomAvatar();
      void controllerClient.init(Number(gamePin), nickname, avatar);
      store.dispatch(setControllerGamePin(gamePin));
      store.dispatch(setControllerAvatar(avatar));
      store.dispatch(setControllerNickname(nickname));
      setIsWaitingOnClientInit(true);
    }
  }, [btnClicked, isInputValid, isWaitingOnClientInit]);

  const resetOnFormChange = () => {
    store.dispatch(controllerClientReady(false));
    store.dispatch(setGameJoinError(''));
    setIsWaitingOnClientInit(false);
    setIsError(false);
    setBtnClicked(false);
  };

  const onGamePinChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGamePin(e.target.value);
    resetOnFormChange();
  };
  const onNicknameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
    resetOnFormChange();
  };

  return (
    <div className="absolute-wrapper">
      {!deviceSupport.isSupported ? (
        <BlockingError title={'Device not supported'} errorMessage={deviceSupport.errorMessage} />
      ) : (
        <div className="main-content">
          <div className="kahoot-theme">
            <div className="content">
              <div className="bg">
                <div className="bg-square"></div>
                <div className="bg-circle"></div>
                <div className="bg-pin">
                  <div className="game-header">
                    <p>
                      Enter the Game PIN and your Nickname, then click the &apos;Join&apos; button to join the game.
                    </p>
                  </div>
                  <JoinError />
                  <div className="game">
                    <img src={kahoot_logo} alt="Kahoot!" className="game-kahoot" />
                    <form className="game-form" autoComplete="off">
                      <input
                        type="text"
                        className="pin-input"
                        id="gamePin"
                        name="gamePin"
                        value={gamePin?.toString() ?? ''}
                        onChange={onGamePinChanged}
                        placeholder="Game PIN*"
                        aria-label="Game PIN"
                      />
                      <input
                        value={nickname?.toString() ?? ''}
                        placeholder="Nickname*"
                        type="text"
                        className="pin-input"
                        id="nickName"
                        name="nickName"
                        onChange={onNicknameChanged}
                        aria-label="Nickname"
                      />
                      <button
                        type="button"
                        className="pin-btn"
                        onClick={onJoinBtnClicked}
                        disabled={btnClicked || !isInputValid}
                      >
                        {joinText}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
