// We have to disable this rule. Pixi is not dynamically loaded.
// eslint-disable-next-line no-restricted-syntax
import { Application, Filter, Sprite, Point } from 'pixi.js';
import { TwistFilter } from '@pixi/filter-twist';
import swirl from '../../features/game-mode/chest/components/send-gems/images/swirl.jpg';

export class PausePixiStage {
  app: Application;
  screenSize = [window.innerWidth, window.innerHeight];
  ratio = this.screenSize[0] / this.screenSize[1];
  swirlFilter: Filter;
  htmlContainer: HTMLElement;

  constructor(container: HTMLElement) {
    this.app = new Application({
      width: this.screenSize[0],
      height: this.screenSize[1],
      resizeTo: window,
      antialias: true,
    });

    this.htmlContainer = container;
    container.appendChild(this.app.view as any);

    this.swirlFilter = new TwistFilter({
      angle: 10,
      radius: 500,
      offset: new Point(window.innerWidth / 2, window.innerHeight / 2),
    });

    const bg = Sprite.from(swirl);
    bg.width = window.innerWidth;
    bg.height = window.innerHeight;
    bg.filterArea = this.app.renderer.screen;
    bg.filters = [this.swirlFilter];
    this.app.stage.addChild(bg);

    this.app.ticker.add((delta) => {
      this.swirlFilter.uniforms.radius += 0.5 * delta;
    });
  }

  destroy() {
    this.app.destroy(true);
  }
}
